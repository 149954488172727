import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

const EmptyCart = () => {
    return ( 
        <div className="emptyCart">
            <ShoppingCartIcon />
            <h2>Koszyk jest pusty</h2>
        </div>
     );
}
 
export default EmptyCart;