import { createContext, useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { fetchLink } from '../Config/cfg'

export const AppContext = createContext();

const AppProvider = ({children}) => {

    const cookies = new Cookies();

    const [isCookiesAccepted, setIsCookiesAccepted] = useState(false)
    const [menu, setMenu] = useState([])

    useEffect(() => {
        const isCookiesExist = cookies.get('acceptedCookies')
        const isUserLoggedCookie = cookies.get('logged')
        if(isCookiesExist === 'true') {
            setIsCookiesAccepted(true)
        }
        if(isUserLoggedCookie) {
            setIsUserLogged(true)
        }   else {
            setIsUserLogged(false)
        }
        // eslint-disable-next-line
    }, [])

    const [openHours, setOpenHours] = useState([])
    useEffect(() => {
        fetch(`${fetchLink}getOpenHours`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => setOpenHours(data.openhours))
    }, [])

    useEffect(() => {
        fetch(`${fetchLink}getMenu`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => setMenu(data.Menu))
    }, [])

    const [isUserLogged, setIsUserLogged] = useState(false)
    const [userData, setUserData] = useState('')

    const userLoggedCookie = cookies.get('logged');
    const userDataCookie = cookies.get('userData');

    useEffect(() => {
        if(userLoggedCookie !== undefined && userDataCookie !== undefined) {
            setIsUserLogged(true)
            setUserData(userDataCookie)
        }
        // eslint-disable-next-line
    }, [])

    //Order context
    useEffect(() => {
        fetch(`${fetchLink}getStore`, {
            method: 'POST',
        })
        .then(response => response.json())
        .then(data => setStore(data))
    }, [])

    const [orderState, setOrderState] = useState(1)
    const [store, setStore] = useState('')
    const [cart, setCart] = useState([])
    const [cartLength, setCartLenth] = useState(0)
    const [order, setOrder] = useState({
        "userData": {
            "name": '',
            "surname": '',
            "email": '',
            "tel": '',
            "city": '',
            "street": '',
            "homeNumber": '',
            "flatNumber": '',
        },
        "orderData": {
            "products": [],
            "uwagi": ''
        },
        "deliveryData": {
            "paymentType": '',
            "paymentAmount": '',
            "deliveryType": '',
            "deliveryTime": ''
        }
    })

    const setItemInCart = (productObject) => {
        const currentCart = cart
        currentCart.push(productObject)
        setCart(currentCart)
        setCartLenth(cart.length)
    }
    const handleNewCartLength = (cartLength) => {
        setCartLenth(cartLength)
    }

   /* const handleSetInfoFromCartToOrder = (cart) => {
        
    }*/
    
    return ( 
        <AppContext.Provider value={{ openHours, handleNewCartLength, cartLength, cart, setCart, setItemInCart, store, order, setOrder, orderState, setOrderState, userData, setUserData, isUserLogged, setIsUserLogged, menu, isCookiesAccepted, setIsCookiesAccepted }}>
            {children}
        </AppContext.Provider>
     );
}
 
export default AppProvider;