import { useState } from 'react';
import Fade from 'react-reveal/Fade'
import { fetchLink } from '../../Config/cfg'
 
const SetNewPassword = (props) => {

    const [isFormSubmitted, setIsFormSubmitted] = useState(false)

    const [password, setPassword] = useState('')
    const [verifiedPassword, setVerifiedPassword] = useState('')
    const [passwordAccepted, setPasswordAccepted] = useState(false)

    const handleSetPassword = (e) => {
        const value = e.target.value;
        setPassword(value)

        if(value.length > 5 && value === verifiedPassword) {
            setPasswordAccepted(true)
        }   else {
            setPasswordAccepted(false)
        }
    }

    const handleSetVerifiedPassword = (e) => {
        const value = e.target.value;
        setVerifiedPassword(value)

        if(value.length > 5 && value === password) {
            setPasswordAccepted(true)
        }   else {
            setPasswordAccepted(false)
        }
    }

    const handleSubmitNewPassword = (e) => {
        e.preventDefault();
        setIsFormSubmitted(true);
        if(passwordAccepted) {
            const passwordToChange = {
                emailToChange: props.email,
                passwordToChange: password
            }
            fetch(`${fetchLink}set-new-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(passwordToChange)
            })
            props.changed();
        }
    }

    return ( 
        <Fade>
        <div className="recovery--wrapper">
            <h1>Ustaw nowe hasło</h1>
            <form id='passwordForm' onSubmit={handleSubmitNewPassword}>
                <label className="next">Nowe hasło</label>
                <input id='passwordInput' type="text" value={password} onChange={handleSetPassword}/>
                { isFormSubmitted ? passwordAccepted ? null : <span>Hasło musi mieć conajmniej 6 znaków i pola haseł muszą być takie same</span> : null }
                <label className="next">Powtórz nowe hasło</label>
                <input id='passwordInput' type="text" value={verifiedPassword} onChange={handleSetVerifiedPassword}/>
                { isFormSubmitted ? passwordAccepted ? null : <span>Hasło musi mieć conajmniej 6 znaków i pola haseł muszą być takie same</span> : null }

                <button>Zapisz zmiany</button>
            </form>
        </div>
        </Fade>
     );
}
 
export default SetNewPassword;