import AccessAlarmsIcon from '@material-ui/icons/AccessAlarms';
import '../../Styles/Orders/timeBlock.css'

const TimeBlocker = () => {
    return ( 
        <div className="timeblocker">
            <AccessAlarmsIcon />
            <h2>Niesety nie przyjmujemy już zamówień</h2>
            <p>Aplikacja umożliwia składanie zamówień Online w godzinach 9:00-23:15 od niedzieli do czwartku i w godzinach 9:00 - 1:45 w piątek i sobotę</p>
        </div>
     );
}
 
export default TimeBlocker;