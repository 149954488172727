import '../../Styles/AccountStyles/recoveryresponse.css';
import Fade from 'react-reveal/Fade'
import stop from '../../Data/error.jpg'

const CodeError = (props) => {
    return ( 
        <Fade>
        <div className="recovery--wrapper">
            <img src={stop} alt="" />
            <h1>Wystąpił bląd</h1>
            <p>Kod nieprawidłowy spróbuj zresetować hasło jeszcze raz
            </p>
            <button onClick={props.tryagain}>Spróbuj ponownie</button>
        </div>
        </Fade>
     );
}
 
export default CodeError;