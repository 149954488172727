import '../../Styles/AccountStyles/login.css'
import { Link, Redirect } from 'react-router-dom'
import Fade from 'react-reveal/Fade';
import { useContext, useState } from 'react'
import { fetchLink } from '../../Config/cfg'
import LoginError from './LoginError';
import { AppContext } from '../../Context/AppContext';
import CircularProgress from '@material-ui/core/CircularProgress';
import Cookies from 'universal-cookie';

const Login = () => {
    const { isUserLogged, setIsUserLogged, setUserData } = useContext(AppContext)

    const scrollToTop = () => {
        window.scroll(0,0)
    }

    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')
    const [rememberMe, setRememberMe] = useState(false)

    const [loginResponse, setLoginResponse] = useState('')
    const [isLoginLoading, setIsLoginLoading] = useState(false)

    const handleLogin = (response) => {
        setLoginResponse(response.loginResponse)
        if(response.loginResponse === 'logged') {
            setIsUserLogged(true)
            setUserData(response.loginData)
            const cookies = new Cookies();
            if(rememberMe === true) {
                cookies.set('logged', true, {
                    maxAge: 5184000
                })
                cookies.set('userData', response.loginData, {
                    maxAge: 5184000
                })
            }   else {
                cookies.set('logged', true)
                cookies.set('userData', response.loginData)
            }
        }
    }

    const handleSetLogin = (e) => {
        setLogin(e.target.value)
    }
    const handleSetPassword = (e) => {
        setPassword(e.target.value)
    }
    const handleSetRememberMe = (e) => {
        setRememberMe(e.target.checked)
    }
    const handleSubmitLogin = (e) => {
        e.preventDefault();
        setIsLoginLoading(true)
        const loginData = {
            login, password, rememberMe
        }
        fetch(`${fetchLink}login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(loginData)
        })
        .then(response => response.json())
        .then(data => handleLogin(data))
    }

    const handleTryAgain = () => {
        setLoginResponse('')
        setIsLoginLoading(false)
    }

    return ( 
        <>
         { loginResponse === 'error' ? <LoginError tryagain={handleTryAgain}/> : 
        <Fade>
        <div className="login__wrapper">
                <form onSubmit={handleSubmitLogin}>
                    <h1>Zaloguj się</h1>
                    <label>Adres E-mail</label>
                    <input type="text" value={login} onChange={handleSetLogin}/>
                    <label>Hasło</label>
                    <input type="password" value={password} onChange={handleSetPassword}/>
                    <div className="manageLogin">
                        <label><input type="checkbox" onChange={handleSetRememberMe}/><p>Nie wylogowuj mnie</p></label>
                        <Link to='/konto/resetowanie-hasla'>Zapomniałeś hasła?</Link>
                    </div>
                    <button disabled={isLoginLoading ? true : false}>{isLoginLoading ? <CircularProgress style={{height: '30px', width: '30px', marginTop: '7.5px', color: 'white'}} /> : 'Zaloguj się'}</button>
                </form>
                <div className="haveAccount">
                    <span></span>
                    <p>Nie masz jeszcze konta?</p>
                </div>
                <Link to='/konto/rejstracja' id='registerLink' onClick={scrollToTop}><button id='registerBtn'>Zarejstruj się</button></Link>
        </div>
        </Fade> }
        {isUserLogged ? <Redirect push to='/konto' /> : null}
        </>
     );
}
 
export default Login;