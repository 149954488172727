import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button';
import pfr from '../../Data/pfr.png';

const Footer = () => {

    const scrollToTop = () => {
        window.scroll(0,0)
    }

    return ( 
        <>
            <ul>
                <li>
                    <Link to='/' onClick={scrollToTop}>Start</Link>
                </li>
                <li>
                    <Link to='/menu' onClick={scrollToTop}>Menu</Link>
                </li>
                <li>
                    <Link to='/kontakt' onClick={scrollToTop}>Kontakt</Link>
                </li>
                <li>
                    <Link to='/konto' onClick={scrollToTop}>Moje Konto</Link>
                </li>
                <li>
                    <Link to='/zamowienie' style={{marginTop: '.7em'}} onClick={scrollToTop}><Button variant="outlined" color="secondary">Zamów Online</Button></Link>
                </li>
            </ul>


            <ul>
                <li>
                    <Link to='/regulamin' onClick={scrollToTop}>Regulamin</Link>
                </li>
                <li>
                    <Link to='/polityka-prywatnosci' onClick={scrollToTop}>Polityka prywatności</Link>
                </li>
                <li>
                    <Link to='/autorzy-grafik' onClick={scrollToTop}>Autorzy grafik</Link>
                </li>
            </ul>

            <p><b>&copy;</b> 2021 <b>Szisza Kebab</b> -  wszelkie prawa zastrzeżone </p>

            <div style={{ width: '100%' }}>
                <img style={{ maxHeight: 100, maxWidth: '100%' }} src={pfr} alt="" />
            </div>
        </>
     );
}
 
export default Footer;