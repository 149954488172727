import finished from '../../Data/ordered.svg'
import '../../Styles/Orders/thanksOrder.css'
import { Link } from 'react-router-dom'
import { useContext } from 'react'
import { AppContext } from '../../Context/AppContext'

const ThanksOrder = () => {

    const { isUserLogged } = useContext(AppContext)

    return ( 
        <div className="thanksorder">
            <img src={finished} alt="" />
            <h1>Dziękujemy za złożenie zamówienia</h1>
            {isUserLogged ? <>
                <p>Przejdź do swojego konta aby sprawdzić status swojego zamówienia.</p>
                <Link to='/konto'>Przejdź do konta</Link>
            </> : <>
            <p>Skontaktujemy się z państwem w razie problemów z zamówieniem na podany numer telefonu lub adres E-mail.</p>
            <Link to='/'>Przejdź strony głównej</Link>
            </>}
        </div>
     );
}
 
export default ThanksOrder;