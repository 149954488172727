import { useContext, useState, useEffect } from "react";
import { AppContext } from "../../Context/AppContext"; 
import '../../Styles/Orders/orderFormAnon.css'
import Select from 'react-select'
import Radio from '@material-ui/core/Radio';
import { fetchLink } from "../../Config/cfg";

const AnonymouseFormData = (props) => {
    const { openHours, cart } = useContext(AppContext)
    // eslint-disable-next-line
    const [currentDay, setCurrentDay] = useState('')
    const [todayStartOpenHour, setTodayStartOpenHour] = useState('')
    const [todayEndOpenHour, setTodayEndOpenHour] = useState('')
   
    const [choosedType, setChoosedType] = useState(1)
    const [paymentType, setPaymentType] = useState(1)
    // eslint-disable-next-line
    const [deliveryTime, setDeliveryTime] = useState('')
    const [refound, setRefound] = useState('')
    const [refoundAccepted, setRefoundAccepted] = useState(false)
    const [uwagi, setUwagi] = useState('')
    const handleSetUwagi = (e) => {
        setUwagi(e.target.value)
    }

    const [finalPrice, setFinalPrice] = useState(0)
    const [productPrice, setProductPrice] = useState(0)
    const [deliveryPrice, setDeliveryPrice] = useState(0)
    const [minimalPrice, setMinimalPrice] = useState('')
    const [postCode, setPostCode] = useState('')

    const handleGetDeliveryPrice = (data) => {
        if(data.length === 0) {
            setPostCode('')
            setDeliveryPrice(10)
            setMinimalPrice(40)
        }   else {
        setPostCode(data.postCode)
        setDeliveryPrice(data.deliveryPrice)
        setMinimalPrice(data.minimalOrderPrice)
        }

        if(choosedType === 2) {
            setMinimalPrice(0)
        }
    }

    useEffect(() => {
        let finPrice = 0;
        for(let i = 0; i < cart.length; i++) {
            finPrice = finPrice + cart[i].finishPrice
        }
        setProductPrice(finPrice)
        // eslint-disable-next-line
    }, [cart.length])

    useEffect(() => {
        if(choosedType === 1) {
        const fullPrice = productPrice + deliveryPrice
        setFinalPrice(fullPrice)
        }   else {
            setFinalPrice(productPrice)
        }

    }, [productPrice, deliveryPrice, choosedType, cart])

    
    const handleSetUnderPayment = () => {
        const underpayment = minimalPrice - productPrice
        return underpayment
    }

    useEffect(() => {
        handleSetDeliveryPrice()
        // eslint-disable-next-line 
    }, [choosedType])

    
    const handleSetStartHours = (hours) => {
        if(typeof(hours) === "object") {
            let uhr = hours[0]
            let startuhr = uhr.split('-')
            setTodayStartOpenHour(startuhr[0])

            let euhr = hours[1]
            let enduhr = euhr.split('-')
            setTodayEndOpenHour(enduhr[1])
        }   else {
            let uhr = hours
            let startuhr = uhr.split('-')
            setTodayStartOpenHour(startuhr[0])
            setTodayEndOpenHour(startuhr[1])
        }
    }

    const [opts, setOpts] = useState('')

    useEffect(() => {
        if(todayStartOpenHour !== '' && todayEndOpenHour !== '') {
            const currHour = new Date()
            let hour = currHour.getHours()
            if(hour < 10) {
                hour = `0${hour}`
            }
            let minutes = currHour.getMinutes()
            if(minutes < 10) {
                minutes = `0${minutes}`
            }
            const currentHour = `${hour}:${minutes}`

            const year = currHour.getFullYear()
            const month = currHour.getMonth()
            const day = currHour.getDate()
       
           // console.log(openHours[currHour.getDay()].hours)
            let endDateObject = new Date()
            if(typeof(openHours[currHour.getDay()].hours) === "object") {
                endDateObject = new Date(year,month, day+1, `${todayEndOpenHour[0]}${todayEndOpenHour[1]}`, `${todayEndOpenHour[3]}${todayEndOpenHour[4]}`).getTime()
            }   else {
                endDateObject = new Date(year,month, day, `${todayEndOpenHour[0]}${todayEndOpenHour[1]}`, `${todayEndOpenHour[3]}${todayEndOpenHour[4]}`).getTime()
            }
            
            // eslint-disable-next-line
            const startDateObject = new Date(year,month, day, `${currentHour[0]}${currentHour[1]}`, `${currentHour[3]}${currentHour[4]}`).getTime()
            var d1 = new Date (),
            d2 = new Date ( d1 );
            let addedMinutes = 15;
            let timecorrected = false;
            while(d2 < new Date(endDateObject)) {
                if(timecorrected === false) {
                d2.setMinutes ( d1.getMinutes() + 60 )
                if(d2.getMinutes() < 15) {
                    d2.setMinutes(15)
                }   else if(d2.getMinutes() > 15 && d2.getMinutes() < 30) {
                    d2.setMinutes(30)
                }   else if(d2.getMinutes() > 30 && d2.getMinutes() < 45) {
                    d2.setMinutes(45)
                }   else if(d2.getMinutes() > 45 && d2.getMinutes() < 60) {
                    d2.setMinutes(60)
                }
                timecorrected = true;
                }   else {
                    d2.setMinutes ( d1.getMinutes() + addedMinutes );
                }
                d1 = d2  
                
                let hour = d2.getHours()
                if(hour < 10) {
                    hour = `0${hour}`
                }
                let minutes = d2.getMinutes()
                if(minutes < 10) {
                    minutes = `0${minutes}`
                }
                const hourminsObject = `${hour}:${minutes}`

                options.push({value: hourminsObject, label: hourminsObject})
            }
            setDeliveryTime(options[0].value)
            setOpts(options)

        }
        // eslint-disable-next-line
    }, [todayStartOpenHour, todayEndOpenHour])

    useEffect(() => {
        const today = new Date()
        const currDay = today.getDay()
        setCurrentDay(openHours[currDay])
        handleSetStartHours(openHours[currDay].hours)
        //setTodayStartOpenHour()
        // eslint-disable-next-line
    }, [])


    const options = [{value: 'Jak najszybciej', label: 'Jak najszybciej'}]
    const handleSetDeliveryTime = (e) => {
        setDeliveryTime(e.value)
    }
    const handleSetRefound = (e) => {
        setRefound(e.target.value)
        if(e.target.value > finalPrice) {
            setRefoundAccepted(true)
        }   else {
            setRefoundAccepted(false)
        }
    }

    const handleSetOrder = (e) => {
        e.preventDefault();
        const nm = name.split(' ')
        const firstName = nm[0]
        const surname = nm[1]
        setIsFormSubmitted(true)
        const orderObject = {
            name: firstName, surname, email, number, city, street, homeNumber, flatNumber, deliveryTime, refound, choosedType, paymentType, uwagi, postCode, finalPrice, deliveryPrice
        }
        if(choosedType === 1) {
            if(nameAccepted && emailAccepted && numberAccepted && cityAccepted && streetAccepted && homeNumberAccepted && productPrice > minimalPrice) {
                props.setNew(orderObject)
            }
        }   else {
            if(nameAccepted && emailAccepted && numberAccepted && productPrice > minimalPrice) {
                props.setNew(orderObject)
            }
        }
    }

    const [name, setName] = useState('')
    const [nameAccepted, setNameAccepted] = useState(false)
    const handleSetName = (e) => {
        const value= e.target.value;
        setName(value)

        const regex = /^([a-zA-ZęóąśłżźćńĘÓĄŚŁŻŹĆŃ]){2,20} ([a-zA-ZęóąśłżźćńĘÓĄŚŁŻŹĆŃ]){2,20}$/ 
        if(regex.test(value)) {
            setNameAccepted(true)
        }   else {
            setNameAccepted(false)
        }
    }

    const [email, setEmail] = useState('')
    const [emailAccepted, setEmailAccepted] = useState(false)
    const handleSetEmail = (e) => {
        const value = e.target.value;
        setEmail(value)
        // eslint-disable-next-line
        const regex = /(^[-\w\.]+@([-\w\.]+)+[a-z]+$)/
        if(regex.test(value)) {
            setEmailAccepted(true)
        }   else {
            setEmailAccepted(false)
        }
    }

    const [number, setNumber] = useState('')
    const [numberAccepted, setNumberAccepted] = useState(false)
    const handleSetNumber = (e) => {
        const value = e.target.value;
        setNumber(value)

        const regex = /^[4-9]{1}[0-9]{8}$/
        if(regex.test(value)) {
            setNumberAccepted(true)
        }   else {
            setNumberAccepted(false)
        }
    }

    const [city, setCity] = useState('')
    const [cityAccepted, setCityAccepted] = useState(false)
    const handleSetCity = (e) => {
        const value = e.target.value;
        setCity(value)

        const cities = ['zabrze', 'ruda śląska', 'gliwice']
        if(cities.indexOf(value.toLowerCase()) !== -1) {
            setCityAccepted(true)
        }   else {
            setCityAccepted(false)
        }
    }
    const [street, setStreet] = useState('')
    const [streetAccepted, setStreetAccepted] = useState(false)
    const handleSetStreet = (e) => {
        const value = e.target.value;
        setStreet(value)

        const regex = /[a-zA-ZęóąśłżźćńĘÓĄŚŁŻŹĆŃ]{3}/
        if(regex.test(value)) {
            setStreetAccepted(true)
        }   else {
            setStreetAccepted(false)
        }
    }

    const handleSetDeliveryPrice = () => {
        if(cityAccepted && streetAccepted) {
            fetch(`${fetchLink}city/${city}/street/${street}`, {
                method: 'POST',
                headers: {
                   "Content-Type": "application/json"
                }
            })
            .then(response => response.json())
            .then(data => handleGetDeliveryPrice(data))
        }
        else {
            handleGetDeliveryPrice([])
        }
    }

    const [homeNumber, setHomeNumber] = useState('')
    const [homeNumberAccepted, setHomeNumberAccepted] = useState(false)
    const handleSetHomeNumber = (e) => {
        const value = e.target.value;
        setHomeNumber(value)

        const regex = /^[1-9]{1}[0-9]{0,2}[a-zA-Z]{0,1}$/
        if(regex.test(value)) {
            setHomeNumberAccepted(true)
        }   else {
            setHomeNumberAccepted(false)
        }
    }
    const [flatNumber, setFlatNumber] = useState('')
    const handleSetFlatNumber = (e) => {
        const value = e.target.value;
        setFlatNumber(value)

        if(value === '') {
            setFlatNumber(null)
        }
    }
    const [isFormSubmitted, setIsFormSubmitted] = useState(false)

    return ( 
        <>
            <form className='orderFormAnon' onSubmit={handleSetOrder}>
                <label>Imię i nazwisko <span>*</span></label>
                <input type="text" value={name} onChange={handleSetName}/>
                { isFormSubmitted ? nameAccepted ? <span></span> : <span>Podaj imię i nazwisko odzielone znakiem spacji</span> : <span></span> }

                <label>Adres E-mail <span>*</span></label>
                <input type="text" value={email} onChange={handleSetEmail}/>
                { isFormSubmitted ? emailAccepted ? <span></span> : <span>Pole nie ma formatu E-mail</span> : <span></span> }

                <label>Numer telefonu <span>*</span></label>
                <input type="text" value={number} onChange={handleSetNumber}/>
                { isFormSubmitted ? numberAccepted ? <span></span> : <span>Numer telefonu podawaj bez spacji i numeru kierunkowego</span> : <span></span> }

                <label id='headerGroup'>Sposób dostawy</label>
                <div className="checkDeliveryType">
                  <label><Radio checked={choosedType === 1 ? true : false} onClick={() => setChoosedType(1)}/><p>Dowóz do domu</p></label>
                  <label><Radio checked={choosedType === 2 ? true : false} onClick={() => setChoosedType(2)}/><p>Odbiór osobisty w lokalu</p></label>
                </div>

                { choosedType === 1 ? <>
                <label id='headerGroup'>Adres dostawy</label>
                <div className='guestAdress'>
                    <label>Miasto <span>*</span></label>
                    <input type="text" value={city} onChange={handleSetCity} onBlur={handleSetDeliveryPrice}/>
                    { isFormSubmitted ? cityAccepted ? <span></span> : <span>Dostarczamy na terenie: Zabrza, Gliwic (sośnica, nie wpisywać dzielnicy w formularzu), Rudy śląskiej</span> : <span></span> }

                    <label>Ulica <span>*</span></label>
                    <input type="text" value={street} onChange={handleSetStreet} onBlur={handleSetDeliveryPrice}/>
                    { isFormSubmitted ? streetAccepted ? <span></span> : <span>Nie podano ulicy</span> : <span></span> }

                    <div className="specAdress">
                        <label>
                            <p>Nr. budynku <span>*</span></p>
                            <input type="text" value={homeNumber} onChange={handleSetHomeNumber}/>
                            { isFormSubmitted ? homeNumberAccepted ? <span></span> : <span>Nie podano numeru</span> : <span></span> }
                        </label>
                        <label>
                            <p>Nr. mieszkania</p>
                            <input type="text" value={flatNumber} onChange={handleSetFlatNumber}/>
                        </label>
                    </div>
                </div></> : null }
                <label id='headerGroup'>Czas {choosedType === 1 ? 'dostawy' : 'odbioru'}</label>
                <Select options={opts} id='timeOrder' defaultValue={options[0]} onChange={handleSetDeliveryTime}/>
                <label id='headerGroup'>Sposób płatności</label>
                <div className="paymentsTypes">
                    <label><Radio checked={paymentType === 1 ? true : false} onClick={() => setPaymentType(1)}/><p>Płatność kartą lub blik</p></label>
                    <label><Radio checked={paymentType === 2 ? true : false} onClick={() => setPaymentType(2)}/><p>Płatność gotówką</p></label>
                </div>
                { paymentType === 2 ?<> <label id='headerGroup'>Masz odliczoną kwotę?</label>
                 <div className="refoundCount">
                    <p>Podaj nominał jakim chcesz zapłacić abyśmy mogli być przygotowani żeby wydać resztę.</p>
                    <input type="number" id='refoundInput' value={refound} onChange={handleSetRefound}/>
                    { isFormSubmitted ? refoundAccepted ? <span></span> : <span>Kwota nie może być mniejsza niż wartość zamówienia</span> : <span></span> }
                </div></> : null }

                <label id='headerGroup'>Uwagi do zamówienia</label>
                <textarea id='uwagiOrder' value={uwagi} onChange={handleSetUwagi}></textarea>

                <div className="paymentValue">
                    { streetAccepted && cityAccepted && minimalPrice !== '' ? minimalPrice > productPrice ? <p id='underpayment'>Brakuje ci <span>{handleSetUnderPayment()}zł</span> do minimalnej wartości zamówienia</p> : null : null }
                    <h2>Do zapłaty: {streetAccepted && cityAccepted ? finalPrice : finalPrice-deliveryPrice}zł</h2>
                    { streetAccepted && cityAccepted && choosedType === 1 ? <span>W tym cena dostawy {deliveryPrice}zł</span> : null }
                </div>
                <button id='orderBtn'>Zamawiam</button>
            </form>
        </>
     );
}
 
export default AnonymouseFormData;