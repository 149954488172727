import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory, Link } from 'react-router-dom'
import '../../Styles/UserData/ordersHistory.css'

import StorefrontIcon from '@material-ui/icons/Storefront';
import { useEffect, useContext, useState } from 'react';
import { fetchLink } from '../../Config/cfg';
import { AppContext } from '../../Context/AppContext';
import '../../Styles/Orders/orderHistory.css'
import InfoIcon from '@material-ui/icons/Info';
import CachedIcon from '@material-ui/icons/Cached';
import BlockIcon from '@material-ui/icons/Block';

const OrdersHistory = () => {

    const { userData } = useContext(AppContext)
    const [orderList, setOrderList] = useState([])

    let history = useHistory()
        
    const goback = () => {
        history.goBack()
    }

    useEffect(() => {
        const userDataObject = {
            userId: userData.id,
            authCode: userData.authCode,
        }
        fetch(`${fetchLink}getOrderList`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(userDataObject)
        })
        .then(response => response.json())
        .then(data => setOrderList(data))
        // eslint-disable-next-line
    }, []) 

     const refreshOrderList = async () => {
        const userDataObject = {
            userId: userData.id,
            authCode: userData.authCode,
        }
        await fetch(`${fetchLink}getOrderList`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(userDataObject)
        })
        .then(response => response.json())
        .then(data => setOrderList(data))   
        setRefreshBlocked(true)
    }

    const [refreshBlocked, setRefreshBlocked] = useState(false)
    const handleClearRefresh = () => {
        setRefreshBlocked(false)
    }
    return ( 
        <div className="dashboard">
            <div className="goBackBtn" onClick={goback}>
                <ArrowBackIcon /> Powrót
            </div>
            <div className="refreshBtn" onClick={refreshBlocked ? null :  refreshOrderList}>{refreshBlocked ? <RefreshBlockedComponent clear={handleClearRefresh}/> : <><CachedIcon /> Odśwież</>}</div>
            <div className="dashboard--info">
                {orderList.length === 0 ? <HistoryBlank /> : orderList.map(order => <OrderItem key={order.id} data={order}/>) }
            </div>
        </div>
     );
}
 
export default OrdersHistory;

const HistoryBlank = () => {
    return ( 
        <div className="history--blank">
            <StorefrontIcon />
            <h2>Brak zamówień</h2>
            <Link to='/zamowienie'>Zamów teraz</Link>
        </div>
     );
}

const OrderItem = (props) => {

    const [displayDetails, setDisplayDetails] = useState(false)
    const data = props.data;
    const handleDisplayDate = () => {
        const dateObject = new Date(data.orderDate);
        const year = dateObject.getFullYear();
        let month = dateObject.getMonth() +1;
        if(month < 10) {
            month = `0${month}`
        }
        let day = dateObject.getDate();
        if(day < 10) {
            day = `0${day}`
        }

        const displayDate = `${year}-${month}-${day}`
        return displayDate
    }

    const handleDisplayTime = () => {
        const dateObject = new Date(data.orderDate);
        let hour = dateObject.getHours();
        if(hour < 10) {
            hour = `0${hour}`
        }
        let minutes = dateObject.getMinutes()
        if(minutes < 10) {
            minutes = `0${minutes}`
        }

        return `${hour}:${minutes}`
    }

    const handleDisplayStatus = (status) => {
        switch (status) {
            case 1:
                return 'Oczekuje na przyjęcie'
            case 2: 
                return 'W realizacji'
            case 3:
                return 'Wydane do dostawy'
            case 4:
                return 'Dostarczone'
            case 9:
                return 'Zamówienie anulowane'
            default:
                return '-'
        }
    }

    return ( 
        <div className="orderItem">
            <p className="orderItem--name">Zamówienie z dnia: {handleDisplayDate()} godz. {handleDisplayTime()}</p>
            <p className="orderItem--price">Wartość zamówienia: <span>{data.finalPrice}zł</span></p>
            <p className="orderItem--status">Status zamówienia: <span id={data.status === 9 ? 'order--anulowane' : 'order--ok'}>{handleDisplayStatus(data.status)}</span></p>
            <div className="order--showDetails" onClick={() => setDisplayDetails(!displayDetails)}>
                <InfoIcon />
            </div>
            { data.status === 2 || data.status === 3 ? <span className="deliveryDate">
                    Twoje zamówienie będzie około: <span>{data.deliveryTime}</span>
                </span> : null }
            { displayDetails ? <div className="order--details">
                <p>Zamówione dania</p>
                <ul>{ JSON.parse(data.products).map((product, i) => <ShowProductDetails key={i} data={product}/>) }</ul>
            </div> : null }
        </div>
     );
}

const ShowProductDetails = (props) => {

    const { store } = useContext(AppContext)
    const data = props.data

    return ( 
        <>
            <li>{ store.store[data.categoryId].products[data.productId].name } {data.sizeChoosed !== "" ? <>(rozmiar {store.store[data.categoryId].products[data.productId].size[data.sizeChoosed]})</> : null}</li>
        </>
     );
}

const RefreshBlockedComponent = (props) => {

    const [counter, setCounter] = useState(10)
    
    useEffect(() => {
        if(counter > 0) {
             setTimeout(() => setCounter(counter - 1), 1000);
        }   else {  
            props.clear()
        }
        // eslint-disable-next-line
    }, [counter])

    return ( 
        <>
            <BlockIcon /> Odświeżenie dostępne za {counter} sekund.
        </>
     );
}