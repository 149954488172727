import React from "react";
import TextTransition, { presets } from "react-text-transition";
import Fade from 'react-reveal/Fade';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

const TEXTS = [
    "Smacznie",
    "Tanio",
    "Szybko"
  ];

const Header = () => {

    const [index, setIndex] = React.useState(0);

    React.useEffect(() => {
        const intervalId = setInterval(() =>
          setIndex(index => index + 1),
          3000
        );
        return () => clearTimeout(intervalId);
      }, []);

      const scrollToMoreContent = () => {
          const screenHeight = window.innerHeight;
          window.scroll(0, screenHeight)
      }

    return ( 
        <Fade>
        <div className="enter">
            <label>
                <h1 style={{width: '100%'}}>Witamy w Szisza Kebab</h1>
                <h1>
                    <TextTransition text={ TEXTS[index % TEXTS.length] } springConfig={ presets.wobbly } />
                </h1>
                <ArrowDownwardIcon onClick={scrollToMoreContent}/>
            </label>
        </div>
        </Fade>
     );
}
 
export default Header;