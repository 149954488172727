import send from '../../Data/send.png'

const ContactFormSended = () => {
    return ( 
        <div className="contactForm--sended">
            <img src={send} alt="" />
            <div className="contactForm--sended--text">
                <h1>Dziękujemy za wysłanie wiadomości</h1>
            </div>
        </div>
     );
}
 
export default ContactFormSended;