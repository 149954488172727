import '../../Styles/AccountStyles/signup.css'
import { Link } from 'react-router-dom'
import Fade from 'react-reveal/Fade';
import { useState } from 'react'
import { fetchLink } from '../../Config/cfg'

import CorrectSignUp from './CorrectSignUp';
import ErrorSignUp from './ErrorSignup';

const Signup = () => {

    const scrollToTop = () => {
        window.scroll(0,0)
    }

    const trySignUp = () => {
        setSignupResponse('')
    }

    const [name, setName] = useState('')
    const [nameAccepted, setNameAccepted] = useState(false)

    const [email, setEmail] = useState('')
    const [emailAccepted, setEmailAccepted] = useState(false)

    const [number, setNumber] = useState('')
    const [numberAccepted, setNumberAccepted] = useState(false)

    const [password, setPassword] = useState('')
    const [passwordAccepted, setPasswordAccepted] = useState(false)

    const [verifyPassword, setVerifyPassword] = useState('')

    const [city, setCity] = useState('')
    const [cityAccepted, setCityAccepted] = useState(false)

    const [street, setStreet] = useState('')
    const [streetAccepted, setStreetAccepted] = useState(false)

    const [homeNumber, setHomeNumber] = useState('')
    const [homeNumberAccepted, setHomeNumberAccepted] = useState(false)

    const [flatNumber, setFlatNubmer] = useState('')

    const [isFormSubmitted, setIsFormSubmitted] = useState(false)

    const [signupResponse, setSignupResponse] = useState('')

    const handleSetName = (e) => {
        const value= e.target.value;
        setName(value)

        const regex = /^([a-zA-ZęóąśłżźćńĘÓĄŚŁŻŹĆŃ]){2,20} ([a-zA-ZęóąśłżźćńĘÓĄŚŁŻŹĆŃ]){2,20}$/ 
        if(regex.test(value)) {
            setNameAccepted(true)
        }   else {
            setNameAccepted(false)
        }
    }
    const handleSetEmail = (e) => {
        const value = e.target.value;
        setEmail(value)
        // eslint-disable-next-line
        const regex = /(^[-\w\.]+@([-\w\.]+)+[a-z]+$)/
        if(regex.test(value)) {
            setEmailAccepted(true)
        }   else {
            setEmailAccepted(false)
        }
    }
    const handleSetNumber = (e) => {
        const value = e.target.value;
        setNumber(value)

        const regex = /^[4-9]{1}[0-9]{8}$/
        if(regex.test(value)) {
            setNumberAccepted(true)
        }   else {
            setNumberAccepted(false)
        }
    }
    const handleSetPassword = (e) => {
        const value = e.target.value;
        setPassword(value)

        if(value.length > 5 && value === verifyPassword) {
            setPasswordAccepted(true)
        }   else {
            setPasswordAccepted(false)
        }
    }
    const handleSetVerifyPassword = (e) => {
        const value = e.target.value;
        setVerifyPassword(value)

        if(value.length > 5 && value === password) {
            setPasswordAccepted(true)
        }   else {
            setPasswordAccepted(false)
        }
    }
    const handleSetCity = (e) => {
        const value = e.target.value;
        setCity(value)

        const cities = ['zabrze', 'ruda śląska', 'gliwice']
        if(cities.indexOf(value.toLowerCase()) !== -1) {
            setCityAccepted(true)
        }   else {
            setCityAccepted(false)
        }
    }
    const handleSetStreet = (e) => {
        const value = e.target.value;
        setStreet(value)

        const regex = /[a-zA-ZęóąśłżźćńĘÓĄŚŁŻŹĆŃ]{3}/
        if(regex.test(value)) {
            setStreetAccepted(true)
        }   else {
            setStreetAccepted(false)
        }
    }
    const handleSetHomeNumber = (e) => {
        const value = e.target.value;
        setHomeNumber(value)

        const regex = /^[1-9]{1}[0-9]{0,2}[a-zA-Z]{0,1}$/
        if(regex.test(value)) {
            setHomeNumberAccepted(true)
        }   else {
            setHomeNumberAccepted(false)
        }
    }
    const handleSetFlatNumber = (e) => {
        const value = e.target.value;
        setFlatNubmer(value)

        if(value === '') {
            setFlatNubmer(null)
        }
    }

    const handleSubmitSignup = (e) => {
        e.preventDefault();
        setIsFormSubmitted(true)
        const signupData = {
            name, email, number, password, city, street, homeNumber, flatNumber
        }

        if(nameAccepted && emailAccepted && numberAccepted && passwordAccepted && cityAccepted && streetAccepted && homeNumberAccepted) {
            fetch(`${fetchLink}signup`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(signupData)
            })
            .then(response => response.json())
            .then(data => setSignupResponse(data.signupResponse))
        }
    }

    return ( 
        <>
        {signupResponse === '' ? 
        <Fade>
        <div className="signup__wrapper">
            <form onSubmit={handleSubmitSignup}>
                <h1>Zarejstruj się</h1>
                <label>Imię i nazwisko <span>*</span></label>
                <input type="text" value={name} onChange={handleSetName} />
                { isFormSubmitted ? nameAccepted ? null : <span>Podaj imię i nazwisko odzielone znakiem spacji</span> : null }

                <label className="next">Adres E-mail <span>*</span></label>
                <input type="text" value={email} onChange={handleSetEmail}/>
                { isFormSubmitted ? emailAccepted ? null : <span>Pole nie ma formatu E-mail</span> : null }

                <label className="next">Numer telefonu <span>*</span></label>
                <input type="text" value={number} onChange={handleSetNumber}/>
                { isFormSubmitted ? numberAccepted ? null : <span>Numer telefonu podawaj bez spacji i numeru kierunkowego</span> : null }

                <label className="next">Hasło <span>*</span></label>
                <input type="password" value={password} onChange={handleSetPassword}/>
                { isFormSubmitted ? passwordAccepted ? null : <span>Hasło musi mieć conajmniej 6 znaków i pola haseł muszą być takie same</span> : null }

                <label className="next">Potwierdź hasło <span>*</span></label>
                <input type="password" value={verifyPassword} onChange={handleSetVerifyPassword}/>
                { isFormSubmitted ? passwordAccepted ? null : <span>Hasło musi mieć conajmniej 6 znaków i pola haseł muszą być takie same</span> : null }

                <label className='next'>Miejscowość <span>*</span></label>
                <input type="text" value={city} onChange={handleSetCity}/>
                { isFormSubmitted ? cityAccepted ? null : <span>Dostarczamy na terenie: Zabrza, Gliwic (sośnica, nie wpisywać dzielnicy w formularzu), Rudy śląskiej</span> : null }

                <label className='next'>Ulica <span>*</span></label>
                <input type="text" value={street} onChange={handleSetStreet}/>
                { isFormSubmitted ? streetAccepted ? null : <span>Nie podano ulicy</span> : null }

                <div className="adresDetails">
                    <label>
                        <p>Nr. domu<span> *</span></p>
                        <input type="text" value={homeNumber} onChange={handleSetHomeNumber}/>
                        { isFormSubmitted ? homeNumberAccepted ? null : <span>Nie podano numeru</span> : null }
                    </label>
                    <label>
                        <p>Nr. mieszkania</p>
                        <input type="number" min='1' value={flatNumber} onChange={handleSetFlatNumber}/>
                    </label>
                </div>
                <button onClick={scrollToTop}>Utwórz konto</button>
            </form>
            <div className="haveAccount">
                <span></span>
                <p>Masz już konto?</p>
            </div>
            <Link to='/konto/logowanie' id='loginLink' onClick={scrollToTop}><button id='loginBtn'>Zaloguj się</button></Link>
        </div>
        </Fade>
        : null}

        {signupResponse === 'correct' ? <CorrectSignUp /> : null}
        {signupResponse === 'error' ? <ErrorSignUp tryagain={trySignUp}/> : null}
        </>
     );
}
 
export default Signup;