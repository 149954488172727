import '../../Styles/Orders/order.css'
import { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../Context/AppContext';

import EnterOrder from './EnterOrder'
import DisplayStore from './DisplayStore';
import SubmitOrder from './SubmitOrder'
import TimeBlocker from './TimeBlocker';
import ThanksOrder from './ThanksOrder';
import { fetchLink } from '../../Config/cfg';
import OrderBreak from './OrderBreak';

const Order = () => {

    const { orderState, setOrderState, openHours, isUserLogged } = useContext(AppContext)
    const [isAppActive, setIsAppActive] = useState(true)
    const [isAppOn, setIsAppOn] = useState(true)

    const handleFetchIsAppOn = (state) => {
        if(state === 0) {
            setIsAppOn(false)
        }   else {
            setIsAppOn(true)
        }
    }

    useEffect(() => {
        fetch(`${fetchLink}getIsAppOn`, {
            method: 'POST',
            headers: {
                'Content-Type':'application/json'
            }
        })
        .then(response => response.json())
        .then(data => handleFetchIsAppOn(data.isOn))
    }, [])

   useEffect(() => {
        const DO = new Date()
        let currentDateObject = new Date()
        let startDate = new Date()
        let startDateObject = new Date()
        let endDate = new Date()
        let endDateObject = new Date()

        const year = DO.getFullYear()
        const month = DO.getMonth()+1
        const day = DO.getDate()
        const currentHour = DO.getHours()
        const currentMinutes = DO.getMinutes()

        currentDateObject = new Date(year, month, day, currentHour, currentMinutes).getTime()

        if(typeof(openHours[DO.getDay()].hours) === 'object') {
            startDate = openHours[DO.getDay()].hours[0].split('-')
            startDate = startDate[0]
            endDate = openHours[DO.getDay()].hours[1].split('-')
            endDate = endDate[1]
            if(currentHour >= 0 && currentHour < 2 ) {
                startDateObject = new Date(year, month, day-1, `${startDate[0]}${startDate[1]}`, `${startDate[3]}${startDate[4]}`).getTime()
                endDateObject = new Date(year, month, day, `${endDate[0]}${endDate[1]}`, `${endDate[3]}${endDate[4]}`).getTime()
            }   else {
                startDateObject = new Date(year, month, day, `${startDate[0]}${startDate[1]}`, `${startDate[3]}${startDate[4]}`).getTime()
                endDateObject = new Date(year, month, day+1, `${endDate[0]}${endDate[1]}`, `${endDate[3]}${endDate[4]}`).getTime()
            }
            /*startDateObject = new Date(year, month, day, `${startDate[0]}${startDate[1]}`, `${startDate[3]}${startDate[4]}`).getTime()
            endDateObject = new Date(year, month, day+1, `${endDate[0]}${endDate[1]}`, `${endDate[3]}${endDate[4]}`).getTime()*/
        }   else {
            startDate = openHours[DO.getDay()].hours.split('-')
            endDate = startDate[1]
            startDate = startDate[0]
            startDateObject = new Date(year, month, day, `${startDate[0]}${startDate[1]}`, `${startDate[3]}${startDate[4]}`).getTime()
            endDateObject = new Date(year, month, day, `${endDate[0]}${endDate[1]}`, `${endDate[3]}${endDate[4]}`).getTime()
        }
        if(currentDateObject > startDateObject && currentDateObject < endDateObject) {
            setIsAppActive(true)
        }   else {
            setIsAppActive(false)
        }
       
        return () => {
            setOrderState(0)
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if(isAppOn === false) {
            setOrderState(10)
        } 
        // eslint-disable-next-line
    }, [isAppOn])

    useEffect(() => {
        if(isAppActive === true) {
            if(isUserLogged) {
                setOrderState(1)
            }   else {
            setOrderState(0)
            }
        }   else {
            setOrderState(9)
        }
        // eslint-disable-next-line
    }, [isAppActive])

    useEffect(() => {
        window.scrollTo(0, 0);
      }, [orderState]);

    return ( 
        <div className="order__wrapper">
            { orderState === 0 ? <EnterOrder /> : null }
            { orderState === 1 ? <DisplayStore /> : null }
            { orderState === 2 ? <SubmitOrder /> : null }
            { orderState === 3 ? <ThanksOrder /> : null }
            { orderState === 9 ? <TimeBlocker /> : null }
            { orderState === 10 ? <OrderBreak /> : null }
        </div>
     );
}
 
export default Order;