import { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../Context/AppContext';
import '../../Styles/Orders/store.css'
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Select from 'react-select';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import Flip from 'react-reveal/Flip';

const DisplayStore = () => {

    const { store } = useContext(AppContext)

    return ( 
        <div className="store__wrapper">
            <div className="store--nav">
                <ul>
                    { store && store.store.map(cat => <li key={`h${cat.id}`}><a href={`#${cat.title}`}>{cat.title}</a></li>) }
                </ul>
            </div>
            { store && store.store.map(category => <Category key={`c${category.id}`} data={category}/>)}
        </div>
     );
}
 
export default DisplayStore;

const Category = (props) => {
    return ( 
        <div className="store--category" id={`${props.data.title}`}>
            <h2>{props.data.title}</h2>
            { props.data.products.map(product => <Product categoryId={props.data.id} key={`p${product.id}`} data={product}/>) }
        </div>
     );
}

const Product = (props) => {   

    const handleDisplayOptions = (option) => {
        option.join()
        const splitString = option.join(", ")
        return splitString
    }

    const handleDisplayPrice = (price) => {
        if(typeof(price) === 'object') {
            price.join()
            const splitPrice = price.join('zł / ')
            
            return splitPrice
        }   else {
            return price
        }
    }

    const [configOption, setConfigOption] = useState(false)
    const handleShowMore = (data) => {
       /* if(data.options === null && data.sos === null && data.meat === null && data.size === null) {
            const categoryId = props.categoryId
            const productId = props.data.id
            const productObject = {
                categoryId,
                productId,
                productCount,
                sosChoosed,
                meatChoosed,
                sizeChoosed,
                addedChoosed,
                addedSos
            }
            setItemInCart(productObject)
        }   else {*/
            setConfigOption(!configOption)
       // }
    }

    //Geting info about product to order
        const { setItemInCart } = useContext(AppContext)
        const categoryId = props.categoryId
        const productId = props.data.id
        const [sosChoosed, setSosChoosed] = useState('')
        const [meatChoosed, setMeatChoosed] = useState('')
        const [addedChoosed, setAddedChoosed] = useState([])
        const [sizeChoosed, setSizeChoosed] = useState('')
        const [addedSos, setAddedSos] = useState([])
        const [addedSosLength, setAddedSosLength] = useState(0)
        const [productCount, setProductCount] = useState(1)
        const [isProductAdded, setIsProductAdded] = useState(false)

        const [price, setPrice] = useState(0)
        const [addsPrice, setAddsPrice] = useState(0)
        const [optionsPrice, setOptionsPrice] = useState(0)

        useEffect(() => {
            let currPrice = price;
            if(typeof(sizeChoosed) === 'number') {
                currPrice = parseFloat(props.data.price[sizeChoosed])
            }   else {
                currPrice = parseFloat(props.data.price)
            }
            setPrice(currPrice)
            
            // eslint-disable-next-line
        }, [sizeChoosed])

        useEffect(() => {
                const tabLength = addedSos.length;
                const addedPrice = Number(3 * tabLength)
                setAddsPrice(addedPrice)
                // eslint-disable-next-line
        }, [addedSosLength])

        useEffect(() => {
            if(addedChoosed !== null) {
                const tabLength = addedChoosed.length
                const optionalPrice = Number(3* tabLength)
                setOptionsPrice(optionalPrice)
            }   else {
                setOptionsPrice(0)
            }
        }, [addedChoosed])


        const handleAddToCart = () => {
            const finishPrice = Number(productCount * (price + addsPrice + optionsPrice))
            const productObject = {
                categoryId,
                productId,
                productCount,
                sosChoosed,
                meatChoosed,
                sizeChoosed,
                addedChoosed,
                addedSos,
                finishPrice
            }
            setItemInCart(productObject)
            setConfigOption(false)
            setIsProductAdded(true)
        }

        useEffect(() => {
            if(isProductAdded === true) {
            setTimeout(() => {
                setIsProductAdded(false)
            }, 5000)}
        }, [isProductAdded])

        const handleToggleProductCount = (type) => {
            if(type === 'increse') {
                if(productCount >= 99) {
                    setProductCount(99)
                } else {
                setProductCount(productCount + 1)
                }
            }   else if(type === 'decrese'){
                if(productCount <= 1) {
                    setProductCount(1)
                }   else {
                setProductCount(productCount - 1)
                }
            }
        }
        const handleSetAddedSos = (list) => {
            setAddedSos(list)
            setAddedSosLength(list.length)
        }
        const handleSetInfoToStore = (type, value) => {
            switch (type) {
                case 'sos':
                    setSosChoosed(value)
                    break;
                case 'meat':
                    setMeatChoosed(value)
                    break;
                case 'options':
                    setAddedChoosed(value)
                    break;
                case 'size':
                    setSizeChoosed(value)
                    break;
                    default:
                    break;
            }
        }
    //

    return ( 
        <div className="product--item">

            {isProductAdded ? <Flip left><div className="productAdded">
            <AddShoppingCartIcon /> <h2>Dodaliśmy produkt do zamówienia</h2>
            </div></Flip> : null }

            <p className="productname">
                {props.data.name}
            </p>
            { props.data.sos !== null ?
            <div className="choosesos">
                Sos do wyboru: {handleDisplayOptions(props.data.sos)}
            </div> : null}
            { props.data.meat !== null ? 
            <div className="chooseMeat">
                Mięso do wyboru: {handleDisplayOptions(props.data.meat)}
            </div> : null }
            { props.data.options !== null ?
            <div className="chooseAddeds">
                Dodatki (dodatkowo płatne): {handleDisplayOptions(props.data.options)}
            </div> : null }
            { props.data.size !== null ? 
            <div className="chooseSize">
                Rozmiary: {handleDisplayOptions(props.data.size)}
            </div> : null }
            <div className="priceOptions">
            {  handleDisplayPrice(props.data.price) }zł
            </div>
            <div className="addProduct" onClick={() => handleShowMore(props.data)}>
                <AddCircleIcon />
            </div>
            <div className={configOption ? 'storeConfig--active' : 'storeConfig'}>
                { props.data.sos === null ? null : <DisplayConfigMeal currvalue={sosChoosed} setInStore={handleSetInfoToStore} type={'sos'} toConfig={props.data.sos} name={'Wybierz sos'}/> }
                { props.data.meat === null ? null : <DisplayConfigMeal currvalue={meatChoosed} setInStore={handleSetInfoToStore}type={'meat'} toConfig={props.data.meat} name={'Wybierz mięso'}/> }
                { props.data.options === null ? null : <DisplayConfigMultiMeal currvalue={addedChoosed} setInStore={handleSetInfoToStore} type={'options'} toConfig={props.data.options} name={'Wybierz dodatki (Każdy płaty 3zł dodatkowo)'}/> }
                { props.data.size === null ? null : <DisplayConfigMeal currvalue={sizeChoosed} setInStore={handleSetInfoToStore} type={'size'} toConfig={props.data.size} name={'Wybierz rozmiar'}/> }

                { props.data.sos === null ? null : <DisplayAddSos currvalue={addedSos} setInStore={handleSetAddedSos} toConfig={props.data.sos}/> }
                <div className="addToCart">
                    <div className="toggleItemCounter">
                        <span onClick={() => handleToggleProductCount('decrese')}><RemoveIcon /></span>
                        <input type="number" value={productCount} min='1' max='99' readOnly/>
                        <span onClick={() => handleToggleProductCount('increse')}><AddIcon /></span>
                    </div>
                    <button onClick={handleAddToCart}><span>( {`${Number(productCount * (price + addsPrice + optionsPrice))} zł`} )</span> Dodaj do koszyka</button>
                </div>
            </div>
        </div>
     );
}

const DisplayConfigMeal = (props) => {

    const options = []
    for(let i = 0; i < props.toConfig.length; i++) {
        options.push({value: i, label: props.toConfig[i]})
    }
    const [choosedMealOption, setChoosedMealOption] = useState(options[0].value)
    const handleChooseMealOption = (e) => {
        setChoosedMealOption(e.value)
    }
    useEffect(() => {
        props.setInStore(props.type, choosedMealOption)
        // eslint-disable-next-line 
    }, [choosedMealOption])

    return ( 
        <>
            <label>{props.name}</label>
            <Select defaultValue={options[0]} options={options} onChange={handleChooseMealOption} placeholder={`${props.name}...`} /> 
        </>
     );
}

const DisplayConfigMultiMeal = (props) => {

    const options = []
    for(let i = 0; i < props.toConfig.length; i++) {
        options.push({value: i, label: props.toConfig[i]})
    }
    let addedTab = [];
    const [choosedMealOption, setChoosedMealOption] = useState([])
    const handleChooseMealOption = (e) => {
        const labelTab = []
        addedTab = e
        if(addedTab.length !== 0) {
           for(let i = 0; i < addedTab.length; i++) {
                labelTab.push(addedTab[i].label)
           }
           setChoosedMealOption(labelTab)
        }
        else {
            setChoosedMealOption(null)
        }
    }
    useEffect(() => {
        props.setInStore(props.type, choosedMealOption)
        // eslint-disable-next-line 
    }, [choosedMealOption])

    return (
        <>
            <label>{props.name}</label>
            <Select isMulti options={options} onChange={handleChooseMealOption} placeholder={`Wybierz dodatki...`} noOptionsMessage={() => 'Nie ma już więcej opcji' || null}/>
        </>
    )
}

const DisplayAddSos = (props) => {

    const options = []
    for(let i = 0; i < props.toConfig.length; i++) {
        options.push(props.toConfig[i])
    }
    const currentChoosed = props.currvalue || []
    const handleToggleAdditionalSos = (type, sauce) => {
        if(type === 'add') {
            currentChoosed.push(sauce)
        }   else if(type === 'remove') {
            const index = currentChoosed.indexOf(sauce)
            currentChoosed.splice(index, 1)
        }
        props.setInStore(currentChoosed)
    }

    return ( 
        <>
        <label>Dodatkowe sosy (Każdy płatny 3zł dodatkowo)</label>
        <div className="displayAdditionSos">
            { options && options.map((option, i) => <DisplayOptionalSos changeSosCouter={handleToggleAdditionalSos} key={i} option={option} number={i}/>) }
        </div>
        </>
     );
}

const DisplayOptionalSos = (props) => {

    const [addedSos, setAddedSos] = useState(0)
    const handleIncreseAddedSos = () => {
        if(addedSos >= 99) {
            setAddedSos(99)
        }   else {
        setAddedSos(addedSos+1)
        props.changeSosCouter('add', props.number)
        }
    }
    const handleRemoveAddedSos = () => {
        if(addedSos <= 0) {
            setAddedSos(0)
        }   else {
        setAddedSos(addedSos-1)
        props.changeSosCouter('remove', props.number)
        }
    }

    return ( 
        <div className="optionalSos">
            <b>{ props.option }</b>
            <div className="couterSos">
                <span onClick={handleRemoveAddedSos}><RemoveIcon /></span>
                <input type="number" readOnly value={addedSos} min='0' max='99'/>
                <span onClick={handleIncreseAddedSos}><AddIcon /></span>
            </div>
        </div>
     );
}