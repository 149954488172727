import { useContext } from "react";
import { AppContext } from "../../Context/AppContext";
import { Switch, Route, Redirect } from 'react-router-dom'

import Dashboard from '../AccountComponents/Dashboard'
import Login from "../AccountComponents/Login";
import Signup from '../AccountComponents/Signup'
import PasswordRecovery from "../AccountComponents/PasswordRecovery";
import OrdersHistory from "../UserDataComponents/OrdersHistory";
import AdressManage from "../UserDataComponents/AdressManage";
import Discounts from '../UserDataComponents/Discounts'

const Account = () => {

    const { isUserLogged } = useContext(AppContext)

    return ( 
        <>
            <Switch>
                <Route exact path='/konto'>
                    {isUserLogged ? <Dashboard /> : <Redirect to='/konto/logowanie' />}
                </Route>

                <Route exact path='/konto/rejstracja'>
                    <Signup />
                </Route>

                <Route exact path='/konto/logowanie'>
                    <Login />
                </Route>

                <Route exact path='/konto/resetowanie-hasla'>
                    <PasswordRecovery />
                </Route>

                <Route exact path='/konto/historia-zamowien'>
                    <OrdersHistory />
                </Route>

                <Route exact path='/konto/adres'>
                    <AdressManage />
                </Route>

                <Route exact path='/konto/rabaty'>
                    <Discounts />
                </Route>
            </Switch>
        </>
     );
}
 
export default Account;