import '../../Styles/AccountStyles/pr.css'
import { Link } from 'react-router-dom'
import Fade from 'react-reveal/Fade';
import { useState } from 'react';
import { fetchLink } from '../../Config/cfg';

import RecoveryError from './RecoveryError';
import RecoveryCode from './RecoveryCode';
import SetNewPassword from './SetNewPassword';
import ChangedPassword from './ChangedPassword';
import CodeError from './CodeError';

const PasswordRecovery = () => {

    const [email, setEmail] = useState('')
    const [emailAccepted, setEmailAccepted] = useState(false)

    const [isFormSubmitted, setIsFormSubmitted] = useState(false)

    const [recoveryResponse, setRecoveryResponse] = useState('')

    const tryagain = () => {
        setRecoveryResponse('')
    }

    const throwError = () => {
        setRecoveryResponse('errorCode')
    }

    const handleCodeAccepted = () => {
        setRecoveryResponse('codeAccepted')
    }

    const handleNewPasswordSetted = () => {
        setRecoveryResponse('changed')
    }

    const handleSetEmail = (e) => {
        const value = e.target.value;
        setEmail(value)

        // eslint-disable-next-line
        const regex = /(^[-\w\.]+@([-\w\.]+)+[a-z]+$)/
        if(regex.test(value)) {
            setEmailAccepted(true)
        }   else {
            setEmailAccepted(false)
        }
    }

    const handleSubmitPasswordRecovery = (e) => {
        e.preventDefault();
        setIsFormSubmitted(true)
        const passwordRevoveryData = {
            email
        }

        if(emailAccepted) {
            fetch(`${fetchLink}password-recovery`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(passwordRevoveryData)
            })
            .then(response => response.json())
            .then(data => setRecoveryResponse(data.recoveryResponse))
        } 
    }

    return ( 
        <>
        { recoveryResponse === '' ? 
        <Fade>
        <div className="pr__wrapper">
            <form onSubmit={handleSubmitPasswordRecovery}>
                <h1>Zresetuj hasło</h1>
                <label>Adres E-mail</label>
                <input type="text" value={email} onChange={handleSetEmail}/>
                { isFormSubmitted ? emailAccepted ? null : <span>Pole nie ma formatu E-mail</span> : null }
                <button>Zresetuj hasło</button>
            </form>
            <div className="haveAccount">
                <span></span>
                <p>Przypomniałeś sobie hasło?</p>
            </div>
            <Link to='/konto/logowanie' id='prLink'><button id='prBtn'>Wróć do logowania</button></Link>
        </div>
        </Fade>: null }

        { recoveryResponse === 'error' ? <RecoveryError tryagain={tryagain}/> : null }

        { recoveryResponse === 'enterCode' ? <RecoveryCode codeAccepted={handleCodeAccepted} throwError={throwError}/> : null }

        { recoveryResponse === 'codeAccepted' ? <SetNewPassword changed={handleNewPasswordSetted} email={email}/> : null }

        { recoveryResponse === 'changed' ? <ChangedPassword /> : null}

        { recoveryResponse === 'errorCode' ? <CodeError tryagain={tryagain}/> : null }
        </>
     );
}
 
export default PasswordRecovery;