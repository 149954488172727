import { useContext } from "react"; 
import { AppContext } from '../Context/AppContext'
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
import ScrollToTop from "./ScrollToTop";

import Navigation from './StaticComponents/Navigation';
import Footer from './StaticComponents/Footer'
import CookiesInfo from "./StaticComponents/CookiesInfo";

import Home from './MainComponents/Home'
import Menu from './MainComponents/Menu'
import Contact from './MainComponents/Contact'
import Account from './MainComponents/Account'
import Terms from './StaticComponents/Terms'
import Privacy from './StaticComponents/Privacy'
import Authors from './StaticComponents/Authors'
import Header from './MainComponents/Header'
import Order from "./Orders/Order";

const App = () => {

    const { isCookiesAccepted } = useContext(AppContext)

    return ( 
        <Router>
            <div className="wrapper">
                <nav>
                    <Navigation />
                </nav>

                <header>
                    <Route exact path='/'>
                        <Header />
                    </Route>
                </header>

                <main>
                    <Switch>
                        <Route exact path='/'>
                            <ScrollToTop />
                            <Home />
                        </Route>

                        <Route exact path='/menu'>
                            <ScrollToTop />
                            <Menu />
                        </Route>

                        <Route exact path='/kontakt'>
                            <ScrollToTop />
                            <Contact />
                        </Route>

                        <Route path='/konto*'>
                            <Account />
                        </Route>

                        <Route exact path='/zamowienie'>
                            <ScrollToTop />
                            <Order />
                        </Route>

                        <Route exact path='/autorzy-grafik'>
                            <Authors />
                        </Route>

                        <Route exact path='/regulamin'>
                            <ScrollToTop />
                            <Terms />
                        </Route>

                        <Route exact path='/polityka-prywatnosci'>
                            <ScrollToTop />
                            <Privacy />
                        </Route>
                    </Switch>
                </main>

                <footer>
                    <Footer />
                </footer>
                { isCookiesAccepted ? null : <CookiesInfo /> }
            </div>
            {/*console.clear()*/}
        </Router>
     );
}
 
export default App;