import '../../Styles/AccountStyles/recoveryresponse.css';
import Fade from 'react-reveal/Fade'
import stop from '../../Data/error.jpg'

const RecoveryError = (props) => {
    return ( 
        <Fade>
            <div className="recovery--wrapper">
                <img src={stop} alt="" />
                <h1>Wystąpił bląd</h1>
                <p>Upewnij się że ten adres E-mail został zarejstrowany w naszym serwsie. Jeżeli nie utworzyłeś jeszcze konta przejdź do rejstracji lub spróbuj ponownie zresetować hasło.
                </p>
                <button onClick={props.tryagain}>Spróbuj ponownie</button>
            </div>
        </Fade>
     );
}
 
export default RecoveryError;