import '../../Styles/MainComponents/Contact.css'
import TextField from '@material-ui/core/TextField';
import { useEffect, useState } from 'react';
import RoomIcon from '@material-ui/icons/Room';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import Button from '@material-ui/core/Button';
import Fade from 'react-reveal/Fade';
import Checkbox from '@material-ui/core/Checkbox';

import { fetchLink } from '../../Config/cfg'
import ContactFormSended from './ContactFormSended';

const Contact = () => {

    const [underlineStart, setUnderlineStart] = useState(false)
    const [secoundUnderlineStart, setSecoundUnderlineStart] = useState(false)
    useEffect(() => {
        setTimeout(() => {
            setUnderlineStart(true)
        }, 500)
        setTimeout(() => {
            setSecoundUnderlineStart(true)
        }, 1000)
        return () => {
            setUnderlineStart(true)
            setSecoundUnderlineStart(true)
        }
    })

    const [isFormSubmited, setIsFormSubmited] = useState(false)

    const [nameInput, setNameInput] = useState('')
    const [nameInputAccepted, setNameInputAccepted] = useState(false)
    const [surnameInput, setSurnameInput] = useState('')
    const [surnameInputAccepted, setSurnameInputAccepted] = useState(false)
    const [emailInput, setEmailInput] = useState('')
    const [emailInputAccepted, setEmailInputAccepted] = useState(false)
    const [tel, setTel] = useState('')
    const [telAccepted, setTelAccepted] = useState(false)
    const [message, setMessage] = useState('')
    const [messageAccepted, setMessageAccepted] = useState(false)
    const [agreement, setAgreement] = useState(false)

    const handleSetNameInput = (e) => {
        const value = e.target.value;
        const regex = /^([a-zA-ZęóąśłżźćńĘÓĄŚŁŻŹĆŃ])+$/

        setNameInput(value)
        if(regex.test(value) && value.length > 2 && value.length < 20) {
            setNameInputAccepted(true)
        }   else {
            setNameInputAccepted(false)
        }
    }
    const handleSetSurnameInput = (e) => {
        const value = e.target.value;
        const regex = /^([a-zA-ZęóąśłżźćńĘÓĄŚŁŻŹĆŃ])+$/

        setSurnameInput(value)
        if(regex.test(value) && value.length > 2 && value.length < 20) {
            setSurnameInputAccepted(true)
        }   else {
            setSurnameInputAccepted(false)
        }
    } 
    const handleSetEmailInput = (e) => {
        const value = e.target.value;
        // eslint-disable-next-line
        const regex = /^[-\w\.]+@([-\w]+\.)+[a-z]+$/

        setEmailInput(value)
        if(regex.test(value)) {
            setEmailInputAccepted(true)
        }   else {
            setEmailInputAccepted(false)
        }
    }
    const handleSetTel = (e) => {
        const value = e.target.value;
        // eslint-disable-next-line
        const regex = /^[0-9\+]{8,13}$/

        setTel(value)
        if(regex.test(value)) {
            setTelAccepted(true)
        }   else {
            setTelAccepted(false)
        }
    }
    const handleSetMessageInput = (e) => {
        const value = e.target.value;

        setMessage(value)
        if(value.length >= 50 && value.length <= 500) {
            setMessageAccepted(true)
        }   else {
            setMessageAccepted(false)
        }
    }
    const handleToggleAgreement = (e) => {
        const checked = e.target.checked;
        setAgreement(checked)
    }

    const handleSendMessage = (e) => {
        e.preventDefault();
        setIsFormSubmited(true)
        if(nameInputAccepted && surnameInputAccepted && emailInputAccepted && telAccepted && messageAccepted && agreement) {
            const messageData = {
                name: nameInput,
                surname: surnameInput,
                email: emailInput,
                tel,
                message
            }
    
            fetch(`${fetchLink}messageReciverMiddleware`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(messageData)
            })
            .then(response => response.json())
            .then(data => setContactFormResponse(data.contactFormResponse))
        }
    }

    const [contactFormResponse, setContactFormResponse] = useState('')

    return ( 
        <>
        { contactFormResponse === 'sended' ? <ContactFormSended /> : 
            <Fade>
            <div className="contactForm">
                <form onSubmit={handleSendMessage}>
                    <h1>Skontaktuj się z nami</h1>
                    <span><span id={underlineStart ? 'header--underline--active' : 'header--underline'}></span></span>
                    <TextField id="standard-basic" label="Imię" value={nameInput} onChange={handleSetNameInput} error={isFormSubmited ? nameInputAccepted ? false : true : null} helperText={isFormSubmited ? nameInputAccepted ? null : 'Pole źle wypełnione' : null}/>
                    <TextField id="standard-basic" label="Nazwisko" value={surnameInput} error={isFormSubmited ? surnameInputAccepted ? false : true : null} onChange={handleSetSurnameInput} helperText={isFormSubmited ? surnameInputAccepted ? null : 'Pole źle wypełnione' : null}/>
                    <TextField id="standard-basic" label="Adres E-mail" value={emailInput} error={isFormSubmited ? emailInputAccepted ? false : true : null} onChange={handleSetEmailInput} helperText={isFormSubmited ? emailInputAccepted ? null : 'Pole musi mieć formę E-mail' : null}/>
                    <TextField id="standard-basic" label="Numer Telefonu" value={tel} error={isFormSubmited ? telAccepted ? false : true : null} onChange={handleSetTel} helperText={isFormSubmited ? telAccepted ? null : 'Numer podawaj bez spacji' : null}/>
                    <TextField id="outlined-multiline-static" label="Twoja wiadomość" value={message} multiline rows={4} variant="outlined" error={isFormSubmited ? messageAccepted ? false : true : null} onChange={handleSetMessageInput} helperText={isFormSubmited ? messageAccepted ? null : 'Wiadomość musi mieć między 50 a 500 znaków' : null}/>
                    <label>
                        <p><Checkbox required color='primary' checked={agreement ? true : false} onChange={handleToggleAgreement}/>Wyrażam zgodę na przetwarzanie moich danych osobowych w sposób określony w polityce prywatności</p>
                        <Button type='submit' id='submitButton' variant="outlined" color="primary">Wyślij wiadomość</Button>
                    </label>
                </form>
            </div></Fade> }
            <Fade>
            <div className="map">
                <h1>Jak do nas dojechać?</h1>
                <span><span id={secoundUnderlineStart ? 'header--underline--active' : 'header--underline'}></span></span>
                <iframe title='locationFrame' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2548.1894622488153!2d18.77899968016652!3d50.307056305668375!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47113212d939e04d%3A0xa3d73077c0d0e789!2sSzisza%20Kebab!5e0!3m2!1spl!2spl!4v1628768279516!5m2!1spl!2spl"></iframe>
                <h3><RoomIcon/> ul. Wolności 263, Zabrze</h3>
                <h3><PhoneIphoneIcon/> 880 611 615</h3>
            </div>
            </Fade> 
        </>
     );
}
 
export default Contact;