import '../../Styles/AccountStyles/dashboard.css'
import { Link } from 'react-router-dom'
import HistoryIcon from '@material-ui/icons/History';
import RoomIcon from '@material-ui/icons/Room';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import Cookies from 'universal-cookie'
import { useContext } from 'react';
import { AppContext } from '../../Context/AppContext'

import LockIcon from '@material-ui/icons/Lock';

const Dashboard = () => {

    const { setIsUserLogged, setUserData, userData } = useContext(AppContext)

    const handleLogout = () => {
        const cookies = new Cookies();
        cookies.remove('logged')
        cookies.remove('userData')
        setIsUserLogged(false)
        setUserData('')
    }

    return ( 
        <div className="dashboard">
            <div className="dashboard--hello">
                <label>
                    <h2>Witaj, {userData.name} {userData.surname}</h2>
                </label>
            </div>
            <ul>
                <li>
                    <HistoryIcon />
                    <span>
                        Historia zamówień
                        <Link to='/konto/historia-zamowien'>Zobacz więcej &rarr;</Link>
                    </span>
                </li>
                <li>
                    <RoomIcon />
                    <span>
                        Mój adres i dane kontaktowe
                        <Link to='/konto/adres'>Zobacz więcej &rarr;</Link>
                    </span>
                </li>
                <li>
                    <div className="disableSetting">
                        <LockIcon />
                        <h2>Niedługo dostępne</h2>
                    </div>
                    <MoneyOffIcon />
                    <span>
                        Rabaty
                        <Link to='/konto/rabaty'>Zobacz więcej &rarr;</Link>
                    </span>
                </li>
                
            </ul>
            <div className="logout" onClick={handleLogout}>
                <ExitToAppIcon />
                Wyloguj się
            </div>
        </div>
     );
}
 
export default Dashboard;