import changed from '../../Data/changed.jpg'
import { Link } from 'react-router-dom'

const ChangedPassword = () => {
    return ( 
        <div className="recovery--wrapper">
            <img src={changed} alt="" />
            <h1>Hasło zmienione pomyślnie</h1>
            <p>Możesz teraz przejść do logowanie i zalogować się nowym hasłem, do zobaczenia w aplikacji.</p>
            <Link id='goLoginLink' to='/konto/logowanie'><button id='goLoginButton'>Przejdź do logowanie</button></Link>
        </div>
     );
}
 
export default ChangedPassword;