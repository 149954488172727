import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom'

const Discounts = () => {

    let history = useHistory()
        
    const goback = () => {
        history.goBack()
    }

    return ( 
        <div className="dashboard">
            <div className="goBackBtn" onClick={goback}>
                <ArrowBackIcon /> Powrót
            </div>
        </div>
     );
}
 
export default Discounts;