import '../../Styles/Orders/order.css'
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import { useContext } from 'react'
import { AppContext } from '../../Context/AppContext';

const OrderSetts = () => {

    const { orderState, cartLength, setOrderState } = useContext(AppContext)
    const handleSkipToSubmit = () => {
        setOrderState(2)
    }

    return ( 
        <>
        { orderState === 1 ? 
        <div className="orderSetts" onClick={handleSkipToSubmit}>
            <LocalMallOutlinedIcon />
            koszyk
            <div className="cartCounter">
                {cartLength}
            </div>
        </div>
        : null }
        </>
     );
}
 
export default OrderSetts;