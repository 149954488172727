import React from 'react';
import { Link, Switch, Route } from 'react-router-dom'
import logo from '../../Data/logo.png'
import Button from '@material-ui/core/Button';
import OrderSetts from '../Orders/OrderSetts';

const Navigation = () => {

    const topToggle = React.createRef();
    const middleToggle = React.createRef();
    const bottomToggle = React.createRef();
    const mobileNav = React.createRef();
    const toggleBtn = React.createRef();

    const handleToggleButton = () => {
        activeTopToggle()
        activeMiddleToggle()
        activeBottomToggle()
        activeToggleBtn()
        activeToggleNav()
    }

    const activeTopToggle = () => {
        const currentAtr = topToggle.current.getAttribute('id')
        if(currentAtr === 'topToggle') {
            topToggle.current.setAttribute('id', 'topToggle--active')
        }   else {
            topToggle.current.setAttribute('id', 'topToggle')
        }
    }

    const activeMiddleToggle = () => {
        const currentAtr = middleToggle.current.getAttribute('id')
        if(currentAtr === 'middleToggle') {
            middleToggle.current.setAttribute('id', 'middleToggle--active')
        }   else {
            middleToggle.current.setAttribute('id', 'middleToggle')
        }
    }   

    const activeBottomToggle = () => {
        const currentAtr = bottomToggle.current.getAttribute('id')
        if(currentAtr === 'bottomToggle') {
            bottomToggle.current.setAttribute('id', 'bottomToggle--active')
        }   else {
            bottomToggle.current.setAttribute('id', 'bottomToggle')
        }
    }

    const activeToggleBtn = () => {
        const currentAtr = toggleBtn.current.getAttribute('id')
        if(currentAtr === 'hiddenToggle') {
            toggleBtn.current.setAttribute('id', 'activeToggle')
        }   else {
            toggleBtn.current.setAttribute('id', 'hiddenToggle')
        }
    }

    const activeToggleNav = () => {
        const currentAtr = mobileNav.current.getAttribute('id');
        if(currentAtr === 'hiddenNav') {
            mobileNav.current.setAttribute('id', 'activeMobileNav')
        }   else {
            mobileNav.current.setAttribute('id', 'hiddenNav')
        }
    }

    return ( 
        <>
        <div className="logo">
            <Link to='/'><img src={logo} alt="szisza kebab logo" /></Link>
        </div>

        <div className="menu">
            <Switch>
            <Route exact path='/zamowienie'>
                <OrderSetts />
            </Route> 
            <Route exact path='/*'>  
            <div className="mobileMenu">
                <div className="toggleBtn" id='hiddenToggle' onClick={handleToggleButton} ref={toggleBtn}>
                    <span id='topToggle' ref={topToggle}></span>
                    <span id='middleToggle' ref={middleToggle}></span>
                    <span id='bottomToggle' ref={bottomToggle}></span>
                </div>
            </div>
            <div className="fullMenu">
                <ul>
                    <li>
                        <Link to='/'>Start</Link>
                    </li>
                    <li>
                        <Link to='/menu'>Menu</Link>
                    </li>
                    <li>
                        <Link to='/kontakt'>Kontakt</Link>
                    </li>
                    <li>
                        <Link to='/konto'>Moje Konto</Link>
                    </li>
                    <li>
                        <Link to='/zamowienie' id='defaultLink'><Button variant="outlined" color="secondary">Zamów Online</Button></Link>
                    </li>
                </ul>
            </div>
            <div className="mobileNav" id='hiddenNav' ref={mobileNav}>
                <ul>
                    <li>
                        <Link to='/' onClick={handleToggleButton}>Start</Link>
                    </li>
                    <li>
                        <Link to='/menu' onClick={handleToggleButton}>Menu</Link>
                    </li>
                    <li>
                        <Link to='/kontakt' onClick={handleToggleButton}>Kontakt</Link>
                    </li>
                    <li>
                        <Link to='/konto' onClick={handleToggleButton}>Moje Konto</Link>
                    </li>
                    <li style={{marginTop: '1em'}}>
                        <Link to='/zamowienie' id='defaultLink'><Button variant="outlined" color="secondary" onClick={handleToggleButton}>Zamów Online</Button></Link>
                    </li>
                </ul>
            </div>
            </Route> 
            </Switch>
        </div>
        </>
     );
}
 
export default Navigation;