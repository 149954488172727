import '../../Styles/Orders/submitOrder.css'
import { useContext } from 'react';
import { AppContext } from '../../Context/AppContext';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';
import EmptyCart from './EmptyCart';
import LoggedFormData from './LoggedFormData';
import AnonymouseFormData from './AnonymouseFormData';
import { fetchLink } from '../../Config/cfg';

const SubmitOrder = () => {

    const { cart, order, setOrderState, isUserLogged } = useContext(AppContext)
    const handleSendNewOrder = (userData) => {
        const completeOrder = order;
        //userData
        completeOrder.userData.name = userData.name
        completeOrder.userData.surname = userData.surname
        completeOrder.userData.city = userData.city
        completeOrder.userData.street = userData.street
        completeOrder.userData.homeNumber = userData.homeNumber
        completeOrder.userData.flatNumber = userData.flatNumber
        completeOrder.userData.tel = userData.number
        completeOrder.userData.email = userData.email
        if(isUserLogged) {
            completeOrder.haveAccount = true
        }   else {
            completeOrder.haveAccount = false
        }
        completeOrder.authCode = userData.authCode
        completeOrder.userId = userData.userId
        //End of userData

        //orderData
        completeOrder.orderData.products = cart
        completeOrder.orderData.uwagi = userData.uwagi
        completeOrder.orderData.finalPrice = userData.finalPrice
        //End of orderData

        //deliveryData
        completeOrder.deliveryData.deliveryTime = userData.deliveryTime
        completeOrder.deliveryData.deliveryType = userData.choosedType
        completeOrder.deliveryData.paymentType = userData.paymentType
        completeOrder.deliveryData.paymentAmount = userData.refound
        completeOrder.deliveryData.postCode = userData.postCode
        completeOrder.deliveryData.deliveryValue = userData.deliveryPrice
        //End of deliveryData
        
        fetch(`${fetchLink}setNewOrder`, {
            method: 'POST',
            headers: {
                "Content-Type": 'application/json'
            },
            body: JSON.stringify({completeOrder: completeOrder})
        })
        setOrderState(3)
    }

    return ( 
        <div className="submit__order">
            <div className="gobacktostore" onClick={() => setOrderState(1)}>
               <ArrowBackIcon /> Wróc do zamówienia
            </div>
            <div className="orderTimeInfo">
                Pierwszy dowóz do klienta możliwy jest od godziny 11:45.
            </div>
           { cart.length === 0 ? null : <div className="submit__order--header"> 
                Podsumowanie zamówienia
            </div> }
            { cart && cart.map((orderItem, i) => <DisplayOrderItem key={i} index={i} data={orderItem}/> ) }
            { cart.length === 0 ? <EmptyCart /> : null }

            { cart.length === 0 ? null : <div className="cartDeliveryInfo">
                <div className="submit__order--header" id='userDataForm'>
                    Dane osobowe i dostawa
                </div>
                { isUserLogged ? <LoggedFormData setNew={handleSendNewOrder}/> : <AnonymouseFormData setNew={handleSendNewOrder}/> }
            </div> }
        </div>
     );
}
 
export default SubmitOrder;

const DisplayOrderItem = (props) => {

    const { store, cart, setCart, handleNewCartLength } = useContext(AppContext)
    const myItem = store.store[props.data.categoryId].products[props.data.productId];
    const handleRemoveFromCart = () => {
        let currentCart = cart;
        currentCart.splice(props.index, 1)
        setCart(currentCart)
        handleNewCartLength(currentCart.length)
    }

    const handleDisplayAdds = (list) => {
        console.log(list);
        const listObj = []
        for(let i = 0; i < list.length; i++) {
            listObj.push(list[i])
            if(i === list.length - 1) {

            }   else {
            listObj.push(', ')
            }
        }
        console.log(listObj);
        return listObj
    }
    return ( 
        <div className="orderItem">
            <h3>{myItem.name}</h3>
            { myItem.size !== null ? <p>Rozmiar: {myItem.size[props.data.sizeChoosed]}</p> : null }
            { myItem.meat !== null ? <p>Mięso: {myItem.meat[props.data.meatChoosed]}</p> : null }
            { myItem.sos !== null ? <p>Sos: {myItem.sos[props.data.sosChoosed]}</p> : null }
            { props.data.addedChoosed.length !== 0 ? <p>Dodatki: {handleDisplayAdds(props.data.addedChoosed)}</p> : null }
            { props.data.addedSos.length !== 0 ? <p>Dodatkowe sosy: {props.data.addedSos.length}</p> : null }
            <p>Ilość produktów: {props.data.productCount}</p>
            <p id='cartItemPrice'>Cena produktu: {props.data.finishPrice} zł</p>
            <div className="removeProduct" onClick={handleRemoveFromCart}>
                <DeleteIcon />
            </div>
        </div>
     );
}