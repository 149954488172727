import  { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button';

import AppPhoto from '../../Data/appinfo.jpg'

const History = () => {

    const openData = [
        {"day": "Poniedziałek", "hours": "09:00 - 00:00"},
        {"day": "Wtorek", "hours": "09:00 - 00:00"},
        {"day": "Środa", "hours": "09:00 - 00:00"},
        {"day": "Czwartek", "hours": "09:00 - 00:00"},
        {"day": "Piątek", "hours": "09:00 - 03:00"},
        {"day": "Sobota", "hours": "09:00 - 03:00"},
        {"day": "Niedziela", "hours": "09:00 - 00:00"},
    ]
   
    const [underlineStart, setUnderlineStart] = useState(false)
    const [secoundUnderlineStart, setSecoundUnderlineStart] = useState(false)
    const [thirdUnderlineStart, setThierdUnderlineStart] = useState(false)
    useEffect(() => {
        setTimeout(() => {
            setUnderlineStart(true)
        }, 500)
        setTimeout(() => {
            setSecoundUnderlineStart(true)
        }, 1000)
        setTimeout(() => {
            setThierdUnderlineStart(true)
        }, 1500)
    })

    return ( 
        <>
            <div className="aboutus">
                <h1>Trochę o nas</h1>
                <span><span id={underlineStart ? 'header--underline--active' : 'header--underline'}></span></span>

                <div className="aboutus--content">
                    <p style={{marginBottom: 0}}>
                    Szisza Kebab to najstarsza i największa restauracja z kuchnią turecką w Zabrzu.
Różnorodność menu sprawia, że każdy Klient znajdzie coś dla siebie.
W lokalu można oglądać TV oraz korzystać z darmowego Wi-Fi.
Szisza Kebab dostarcza również swoje dania bezpośrednio do Klientów w Zabrzu oraz miastach ościennych. 
Goście odwiedzający tą restaurację twierdzą, że Szisza Kebab to jeden z najlepszych lokali gastronomicznych
 w regionie dlatego każdy powinien ją odwiedzić i spróbować kuchni tureckiej.
                    </p>
                        <table>
                        <caption>Godziny otwarcia</caption>
                        <tbody>
                            {openData.map((day, i) => (
                                <tr key={i}>
                                    <td>{day.day}</td>
                                    <td>{day.hours}</td>
                                </tr>
                            ))}
                        </tbody>
                        </table>
                </div>
            </div>

            <div className="newportal">
                <h1>Uruchomiliśmy nasz portal do zamawiania online</h1>
                <span><span id={secoundUnderlineStart ? 'header--underline--active' : 'header--underline'}></span></span>
                
                <div className="newportal--content">
                    <div className="newportal--content--photo">
                        <img src={AppPhoto} alt="" />
                    </div>
                    <div className="newportal--content--desc">
                        Uruchomiliśmy własny portal do obsługi zamówień online. 
                        <Link to='/zamowienie'><Button variant="outlined" color="secondary">Zamów online</Button></Link>
                    </div>
                </div>
            </div>

            <div className="ourmenu">
                <h1>Nasze menu</h1>
                <span><span id={thirdUnderlineStart ? 'header--underline--active' : 'header--underline'}></span></span>
                
                <div className="ourmenu--content">
                    <p>Zachęcamy państwa do zapoznania się z naszym menu. Jesteśmy pewni, że każdy znajdzie coś dla siebie. Smacznego!</p>
                    <Link to='/menu'><Button variant="outlined" color="secondary">Przejdź do menu</Button></Link>
                </div>
            </div>
        </>
     );
}
 
export default History;