import Fade from 'react-reveal/Fade'
import stop from '../../Data/stop.jpg'

const LoginError = (props) => {
    return ( 
        <Fade>
            <div className="loginError">
                <img src={stop} alt="" />
                <h1>Nie udało się zalogować</h1>
                <h3>Upewnij się że wprowadziłeś poprawne dane logowania lub zresetuj hasło</h3>
                <button onClick={props.tryagain}>Spróbuj ponownie</button>
            </div>
        </Fade>
     );
}
 
export default LoginError;