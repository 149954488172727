import { useState, useEffect } from 'react';

import History from './History'
import Fade from 'react-reveal/Fade';

import '../../Styles/MainComponents/Home.css'
import RoomIcon from '@material-ui/icons/Room';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';

const Home = () => {

    const [secoundUnderlineStart, setSecoundUnderlineStart] = useState(false)
    useEffect(() => {
        setTimeout(() => {
            setSecoundUnderlineStart(true)
        }, 2000)
    })

    return ( 
        <>
        <Fade>
            <History />
            <div className="map" id='main-page-map'>
                <h1>Jak do nas dojechać?</h1>
                <span><span id={secoundUnderlineStart ? 'header--underline--active' : 'header--underline'}></span></span>
                <iframe title='locationFrame' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2548.1894622488153!2d18.77899968016652!3d50.307056305668375!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47113212d939e04d%3A0xa3d73077c0d0e789!2sSzisza%20Kebab!5e0!3m2!1spl!2spl!4v1628768279516!5m2!1spl!2spl"></iframe>
                <h3><RoomIcon/> ul. Wolności 263, Zabrze</h3>
                <h3><PhoneIphoneIcon/> 880 611 615</h3>
            </div>
        </Fade>
        </>
     );
}
 
export default Home;