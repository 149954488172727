import '../../Styles/Orders/enterorder.css'
import { useContext, useEffect } from 'react'
import { AppContext } from '../../Context/AppContext';
import { Link } from 'react-router-dom'

const EnterOrder = () => {

    return ( 
        <div className='enterorder'>
             <AnonymousEnter />
        </div>
     );
}
 
export default EnterOrder;

const AnonymousEnter = () => {

    const { isUserLogged, setOrderState } = useContext(AppContext)
    useEffect(() => {
        if(isUserLogged) {
            setOrderState(1)
        }
        // eslint-disable-next-line
    }, [])
    return ( 
        <div className="anonenter">
            <div className="proposeAccount">
                <h2>Czy chcesz się zalogować?</h2>
                <p>Zaloguj się na swoje konto lub zarejstruj się w naszej aplikacji, aby przyspieszyć proces składanie zamówienia.
                    Na twoim koncie będzie zapisywać się historia zamówień.
                </p>
                <Link to='/konto'>Przejdź do logowania</Link>
            </div>
            <div className="continueAnonymouse" onClick={() => setOrderState(1)}>
                Zamawiam bez konta
            </div>
        </div>
     );
}