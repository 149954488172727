import '../../Styles/StaticComponents/authors.css'

import g1 from '../../Data/stop.jpg';
import g2 from '../../Data/created.jpg';
import g3 from '../../Data/error.jpg';
import g4 from '../../Data/changed.jpg'

const Authors = () => {

    const authorObject = [
        {
            "graphicLink": g1,
            "authorLink": <a href='https://pl.freepik.com/wektory/tlo'>Tło plik wektorowy utworzone przez rawpixel.com - pl.freepik.com</a>
        }, 
        {
            "graphicLink": g2,
            "authorLink": <a href='https://www.freepik.com/vectors/avatar'>Avatar vector created by stories - www.freepik.com</a>
        },
        {
            "graphicLink": g3,
            "authorLink": <a href='https://www.freepik.com/vectors/people'>People vector created by pch.vector - www.freepik.com</a>
        },
        {
            "graphicLink": g4,
            "authorLink": <a href='https://www.freepik.com/vectors/office'>Office vector created by stories - www.freepik.com</a>
        }
    ]

    return ( 
        <div className="authors__wrapper">
            <table>
                <thead>
                    <tr>
                        <th>Grafika</th>
                        <th>Link autora</th>
                    </tr>
                </thead>
                <tbody>
                    { authorObject.map((author, i) => (
                        <tr key={i}>
                            <td><img src={author.graphicLink} alt="" /></td>
                            <td>{author.authorLink}</td>
                        </tr>
                    )) }
                </tbody>
            </table>
        </div>
     );
}
 
export default Authors;