import { useState, createRef } from 'react'
import { fetchLink } from '../../Config/cfg' 
import Fade from 'react-reveal/Fade'
import CircularProgress from '@material-ui/core/CircularProgress';

const RecoveryCode = (props) => {

    const [isCodeSended, setIsCodeSended] = useState(false)

    const input1 = createRef();
    const input2 = createRef();
    const input3 = createRef();
    const input4 = createRef();
    const input5 = createRef();
    const input6 = createRef();

    const [code, setCode] = useState('')
    const updateCode = (e) => {
        const character = e.target.value;

        let currentCode = code;
        if(character === '') {
            currentCode = code.substring(0, code.length - 1);
        }   else {
            currentCode = code + character;
        }
        
        setCode(currentCode)
        
        switch (currentCode.length) {
            case 0:
                input1.current.focus();
                break;
            case 1:
                input2.current.focus();
                break;
            case 2:
                input3.current.focus();
                break;
            case 3:
                input4.current.focus();
                break;
            case 4:
                input5.current.focus();
                break;
            case 5:
                input6.current.focus();
                break;
            case 6:
                setIsCodeSended(true);
                handleSubmitCode(currentCode)
                break;

            default:
                break;
        }
    }

    const handleVerifyStep = (response) => {
        console.log(response)
        if(response === 'yes') {
            props.codeAccepted()
        }   else {
            props.throwError();
        }
    }

    const handleSubmitCode = (code) => {
        const userCode = {
            userCode: code
        }

        fetch(`${fetchLink}code-check`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(userCode)
        })
        .then(response => response.json())
        .then(data => handleVerifyStep(data.isCodeCorrect))
    }

    return ( 
        <>
        {isCodeSended ? <CodeLoading /> : null }
        <Fade>
        <div className="recovery--wrapper">
            <h1>Wysłaliśmy kod weryfikacyjny</h1>
            <p>Na maila wysłaliśmy kod weryfikacyjny, proszę wpisać go w pola poniżej.</p>
            <form>
                <input type="text" ref={input1} maxLength='1' disabled={code.length === 6 ? true : false} onChange={updateCode} autoFocus/>
                <input type="text" ref={input2} maxLength='1' disabled={code.length === 6 ? true : false} onChange={updateCode} />
                <input type="text" ref={input3} maxLength='1' disabled={code.length === 6 ? true : false} onChange={updateCode} />
                <input type="text" ref={input4} maxLength='1' disabled={code.length === 6 ? true : false} onChange={updateCode} />
                <input type="text" ref={input5} maxLength='1' disabled={code.length === 6 ? true : false} onChange={updateCode} />
                <input type="text" ref={input6} maxLength='1' disabled={code.length === 6 ? true : false} onChange={updateCode} />
            </form>
        </div>
        </Fade>
        </>
     );
}
 
export default RecoveryCode;

const CodeLoading = () => {
    return ( 
        <div className="code--loading">
            <CircularProgress style={{height: '100px', width: '100px', color: 'white'}} />
        </div>
     );
}