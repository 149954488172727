import '../../Styles/AccountStyles/signupresponse.css'
import { Link } from 'react-router-dom'
import created from '../../Data/created.jpg'
import Fade from 'react-reveal/Fade';

const CorrectSignUp = () => {
    return ( 
        <Fade>
        <div className="formResponse--wrapper">
            <img src={created} alt="" />
            <h1>Konto utworzone!</h1>
            <p>Na maila przesłaliśmy link do aktywacji konta. Po aktywacji konta będzie możliwe zalogowanie się do panelu klienta.</p>

            <Link to='/konto/logowanie'><button>Przejdź do logowania</button></Link>
        </div>
        </Fade>
     );
}
 
export default CorrectSignUp;