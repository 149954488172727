import '../../Styles/StaticComponents/terms.css'
import { useHistory } from 'react-router-dom';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const Terms = () => {
        let history = useHistory()
        
        const goback = () => {
            history.goBack()
        }
    return ( 
        <div className="terms">
            <button className='pageBackBtn' onClick={goback}><ArrowBackIcon style={{fontSize: '24px', marginRight: '.3em'}}/>Powrót</button>
            <h1>Regulamin aplikacji</h1>
            <ul>
                <li>Strona została stworzona w celach reklamowych resturacji Szisza Kebab oraz do składania zamówień online.</li>
                <li>Korzystając z aplikacji wyrażasz zgodę na przetwarzanie danych osobowych podanych w celu realizacji zamówienia lub na potrzeby administracyjne strony internetowej zgodnie z zasadami zawartymi w polityce prywatności.</li>
                <li>Restauracja może odmówić realizacji zamówienia bez podania przyczyny, lecz po wcześniejszym poinformowaniu klienta. Restauracja ma 7 dni na zwrot środków przy płatności online jeżeli zamówienie nie zostanie zreazlizowane z winy restauracji. </li>
                <li>Restauracja zastrzega sobie prawo do zachowania środków za niezrealizowane zamówienia z winy klienta. Jeżeli klient nie odbierze zamówienia oraz wybrał sposób płatności gotówką lub płatność kartą przy odbiorze, restauracja ma możliwość podjąć odpowiednie kroki prawne i żądać od klienta pokrycia kosztów zamówienia.</li>
                <li>Założenie konta w aplikacji jest bezpłatne.</li>
                <li>Klientowi nie przysługuje prawo do zwrotu pieniędzy za posiłek zamówiony poprzez portal.</li>
            </ul>
        </div>
     );
}
 
export default Terms;