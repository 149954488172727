import { useContext, useState } from "react";
import { AppContext } from "../../Context/AppContext";
import '../../Styles/MainComponents/Menu.css';
import InfoIcon from '@material-ui/icons/Info';
import Fade from 'react-reveal/Fade';

const Menu = () => {
    const { menu } = useContext(AppContext) 
    return ( 
        <>
            {menu.map(category => <Category key={category.id} data={category}/>)}
        </>
     );
}
 
export default Menu;

const Category = (props) => {
    const data = props.data
    return ( 
        <div className='menu--category'>
        <Fade><h2>{data.title}</h2>
        <div className="header__underline"></div></Fade>
        {data.products.map(product => <Fade key={product.id}><Product data={product}/></Fade>)}
        </div>
     );
}

const Product = (props) => {
    const data = props.data;
    const [showMoreInfo, setShowMoreInfo] = useState(false)
    return ( 
        <div className='menu--product'>
        <h3 className='menuTitle'>{data.name} <InfoIcon onClick={() => setShowMoreInfo(!showMoreInfo)}/></h3>
        <p className='menuInfo'>{data.info !== null ? data.info : null}</p>
        <p className='menuOptions'>{data.options !== null ? <span className='menuOptions--bigger'>Do wyboru: {data.options}</span> : null}</p>
        <p className='menuPrice'>{data.price} zł</p>
        { showMoreInfo ? <div className='menuMoreInfo'>
            <h3>Substancje lub produkty mogące powodować alergie i nietolerancje pokarmowe</h3>
            <ul>{data.subs !== null ? data.subs.map((sub, i) => <li key={i}>{sub}</li>) : '-'}</ul>
            <h3>Alergeny</h3>
            <ul>{data.alergen !== null ? data.alergen.map((alerg, i) => <li key={i}>{alerg}</li>) : '-'}</ul>
        </div> : null }
        </div>
     );
}