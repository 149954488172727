import { Link } from 'react-router-dom'
import error from '../../Data/error.jpg'
import Fade from 'react-reveal/Fade';

const ErrorSignUp = (props) => {
    return ( 
        <Fade>
        <div className="formResponse--wrapper">
            <img src={error} alt="" />
            <h1>Wystąpił błąd podczas rejstracji</h1>
            <p>Upewnij się czy nie masz już konta w naszym serwisie. Jeżeli widzisz ten komunikat to znaczy że podany adres E-mail lub telefon został zarejstrowany w naszej bazie.</p>
            <Link onClick={props.tryagain}><button>Spróbuj ponownie</button></Link>
        </div>
        </Fade>
     );
}
 
export default ErrorSignUp;