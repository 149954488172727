import '../../Styles/Orders/orderbreak.css'
import SpeedIcon from '@material-ui/icons/Speed';

const OrderBreak = () => {
    return ( 
        <div className="orderbreak">
            <SpeedIcon />
            <h2>Obecnie nie przyjmujemy zamówień</h2>
            <p>Przerwa w przyjmowaniu zamówień została spowodowana przez zbyt dużą liczbę realizowanych zamówień, zachęcamy do powrotu na stronę za niedługo.</p>
        </div>
     );
}
 
export default OrderBreak;