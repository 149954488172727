import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';
import { useContext, useState } from 'react'
import { AppContext } from '../../Context/AppContext';
import '../../Styles/UserData/adressmanage.css'
import { fetchLink } from '../../Config/cfg'; 
import CheckIcon from '@material-ui/icons/Check';
import Cookies from 'universal-cookie';

const AdressManage = () => {

    let history = useHistory()
        
    const goback = () => {
        history.goBack()
    }

    const { userData, setUserData } = useContext(AppContext)

    const [city, setCity] = useState(userData.city)
    const [street, setStreet] = useState(userData.street)
    const [homeNumber, setHomeNumber] = useState(userData.homeNumber)
    const [flatNumber, setFlatNumber] = useState(userData.flatNumber)
    const [phoneNumber, setPhoneNumber] = useState(userData.number)

    const [cityAccepted, setCityAccepted] = useState(true)
    const [streetAccepted, setStreetAccepted] = useState(true)
    const [homeNumberAccepted, setHomeNumberAccepted] = useState(true)
    const [phoneNumberAccepted, setPhoneNumberAccepted] = useState(true)

    const [isFormSubmitted, setIsFormSubmitted] = useState(false) 
    const [changeResponse, setChangeResponse] = useState(false)

    const handleSetCity = (e) => {
        const value = e.target.value;
        setCity(value)
        const cities = ['zabrze', 'ruda śląska', 'gliwice']
        if(cities.indexOf(value.toLowerCase()) !== -1) {
            setCityAccepted(true)
        }   else {
            setCityAccepted(false)
        }
    }
    const handleSetStreet = (e) => {
        const value = e.target.value;
        setStreet(value)
        const regex = /[a-zA-ZęóąśłżźćńĘÓĄŚŁŻŹĆŃ]{3}/
        if(regex.test(value)) {
            setStreetAccepted(true)
        }   else {
            setStreetAccepted(false)
        }
    }
    const handleSetHomeNumber = (e) => {
        const value = e.target.value;
        setHomeNumber(value)
        const regex = /^[1-9]{1}[0-9]{0,2}[a-zA-Z]{0,1}$/
        if(regex.test(value)) {
            setHomeNumberAccepted(true)
        }   else {
            setHomeNumberAccepted(false)
        }
    }
    const handleSetFlatNumber = (e) => {
        const value = e.target.value;
        setFlatNumber(value)

        if(value === '') {
            setFlatNumber(null)
        }
    }
    const handleSetPhoneNumber = (e) => {
        const value = e.target.value;
        setPhoneNumber(value)
        const regex = /^[4-9]{1}[0-9]{8}$/
        if(regex.test(value)) {
            setPhoneNumberAccepted(true)
        }   else {
            setPhoneNumberAccepted(false)
        }
    }
    const handleSubmitChanges = (e) => {
        e.preventDefault()
        setIsFormSubmitted(true)
        const newCookiesObject = {
            id: userData.id,
            authCode: userData.authCode,
            verified: userData.verified,
            name: userData.name,
            surname: userData.surname,
            email: userData.email,
            number: userData.number,
            password: userData.password,
            city: city,
            street: street,
            homeNumber: homeNumber,
            flatNumber: flatNumber,
            createDate: userData.createDate
        }
        const changeObject = {
            authCode: userData.authCode,
            city,
            street,
            homeNumber,
            flatNumber,
            phoneNumber
        }
        if(cityAccepted && streetAccepted && homeNumberAccepted && phoneNumberAccepted) {
            fetch(`${fetchLink}change-delivery`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(changeObject)
            })
            setChangeResponse(true)
            const cookies = new Cookies();
            cookies.set('userData', newCookiesObject, {
                maxAge: 5184000
            })
            setUserData(newCookiesObject)
        }
    }

    return ( 
        <div className="dashboard">
            <div className="goBackBtn" onClick={goback}>
                <ArrowBackIcon /> Powrót
            </div>
            <div className="dashboard--info" style={{justifyContent:'flex-start', alignItems: 'flex-start'}}>
                <form onSubmit={handleSubmitChanges}>
                    <div className="adress--mod">
                        <span>Adres dostawy</span>
                        <label>Miasto</label>
                        <input type="text" value={city} onChange={handleSetCity}/>
                        { isFormSubmitted ? cityAccepted ? <p></p> : <p>Dostarczamy na terenie: Zabrza, Gliwic (sośnica, nie wpisywać dzielnicy w formularzu), Rudy śląskiej</p> : <p></p> }
                        <label>Ulica</label>
                        <input type="text" value={street} onChange={handleSetStreet}/>
                        { isFormSubmitted ? streetAccepted ? <p></p> : <p>Nie podano ulicy</p> : <p></p> }
                        <label>Nr. domu</label>
                        <input type="text"  value={homeNumber} onChange={handleSetHomeNumber}/>
                        { isFormSubmitted ? homeNumberAccepted ? <p></p> : <p>Nie podano numeru</p> : <p></p> }
                        <label>Nr. mieszkania</label>
                        <input type="number" min='1' value={flatNumber} onChange={handleSetFlatNumber}/>
                        <p></p>
                    </div>
                    <div className="contact--mod">
                        <span>Dane kontaktowe</span>
                        <label>Numer kontaktowy</label>
                        <input type="text" value={phoneNumber} onChange={handleSetPhoneNumber}/>
                        { isFormSubmitted ? phoneNumberAccepted ? <p></p> : <p>Numer telefonu podawaj bez spacji i numeru kierunkowego</p> : <p></p> }
                    </div>
                    <button disabled={isFormSubmitted ? true : false}>{changeResponse ? <>Zapisano zmiany <CheckIcon /></> : 'Zapisz zmiany'}</button>
                </form>
            </div>
        </div>
     );
}
 
export default AdressManage;