import { useContext, useState, useEffect } from "react";
import { AppContext } from "../../Context/AppContext"; 
import { fetchLink } from "../../Config/cfg";
import '../../Styles/Orders/orderForm.css'
import HomeIcon from '@material-ui/icons/Home';
import Select from 'react-select'
import Radio from '@material-ui/core/Radio';

const LoggedFormData = (props) => {

    const { userData, openHours, cart } = useContext(AppContext)
    const [isFormSubmitted, setIsFormSubmitted] = useState(false)
    // eslint-disable-next-line
    const [currentDay, setCurrentDay] = useState('')
    const [todayStartOpenHour, setTodayStartOpenHour] = useState('')
    const [todayEndOpenHour, setTodayEndOpenHour] = useState('')
   
    const [choosedType, setChoosedType] = useState(1)
    const [paymentType, setPaymentType] = useState(1)
    // eslint-disable-next-line
    const [deliveryTime, setDeliveryTime] = useState('')
    const [refound, setRefound] = useState('')
    const [refoundAccepted, setRefoundAccepted] = useState(false)
    const [uwagi, setUwagi] = useState('')
    const handleSetUwagi = (e) => {
        setUwagi(e.target.value)
    }

    const [finalPrice, setFinalPrice] = useState(0)
    const [productPrice, setProductPrice] = useState(0)
    const [deliveryPrice, setDeliveryPrice] = useState(0)
    const [minimalPrice, setMinimalPrice] = useState('')
    const [postCode, setPostCode] = useState('')

    useEffect(() => {
        fetch(`${fetchLink}city/${userData.city}/street/${userData.street}`, {
            method: 'POST',
            headers: {
               "Content-Type": "application/json"
            }
        })
        .then(response => response.json())
        .then(data => handleGetDeliveryPrice(data))
        // eslint-disable-next-line
    }, [choosedType])

    const handleGetDeliveryPrice = (data) => {
        if(data.length === 0) {
            setPostCode('')
            setDeliveryPrice(10)
            setMinimalPrice(40)
        }   else {
        setPostCode(data.postCode)
        setDeliveryPrice(data.deliveryPrice)
        setMinimalPrice(data.minimalOrderPrice)
        }

        if(choosedType === 2) {
            setMinimalPrice(0)
        }
    }

    useEffect(() => {
        let finPrice = 0;
        for(let i = 0; i < cart.length; i++) {
            finPrice = finPrice + cart[i].finishPrice
        }
        setProductPrice(finPrice)
        // eslint-disable-next-line
    }, [cart.length])

    useEffect(() => {
        if(choosedType === 1) {
        const fullPrice = productPrice + deliveryPrice
        setFinalPrice(fullPrice)
        }   else {
            setFinalPrice(productPrice)
        }

    }, [productPrice, deliveryPrice, choosedType, cart])

    const handleSetUnderPayment = () => {
        const underpayment = minimalPrice - productPrice
        return underpayment
    }
    
    const handleSetStartHours = (hours) => {
        if(typeof(hours) === "object") {
            let uhr = hours[0]
            let startuhr = uhr.split('-')
            setTodayStartOpenHour(startuhr[0])

            let euhr = hours[1]
            let enduhr = euhr.split('-')
            setTodayEndOpenHour(enduhr[1])
        }   else {
            let uhr = hours
            let startuhr = uhr.split('-')
            setTodayStartOpenHour(startuhr[0])
            setTodayEndOpenHour(startuhr[1])
        }
    }

    const [opts, setOpts] = useState('')

    useEffect(() => {
        if(todayStartOpenHour !== '' && todayEndOpenHour !== '') {
            const currHour = new Date()
            let hour = currHour.getHours()
            if(hour < 10) {
                hour = `0${hour}`
            }
            let minutes = currHour.getMinutes()
            if(minutes < 10) {
                minutes = `0${minutes}`
            }
            const currentHour = `${hour}:${minutes}`

            const year = currHour.getFullYear()
            const month = currHour.getMonth()
            const day = currHour.getDate()
       
            let endDateObject = new Date()
            if(typeof(openHours[currHour.getDay()].hours) === "object") {
                endDateObject = new Date(year,month, day+1, `${todayEndOpenHour[0]}${todayEndOpenHour[1]}`, `${todayEndOpenHour[3]}${todayEndOpenHour[4]}`).getTime()
            }   else {
                endDateObject = new Date(year,month, day, `${todayEndOpenHour[0]}${todayEndOpenHour[1]}`, `${todayEndOpenHour[3]}${todayEndOpenHour[4]}`).getTime()
            }
            // eslint-disable-next-line
            const startDateObject = new Date(year,month, day, `${currentHour[0]}${currentHour[1]}`, `${currentHour[3]}${currentHour[4]}`).getTime()

            var d1 = new Date (),
            d2 = new Date ( d1 );
            let addedMinutes = 15;
            let timecorrected = false;
            while(d2 < new Date(endDateObject)) {
                if(timecorrected === false) {
                d2.setMinutes ( d1.getMinutes() + 60 )
                if(d2.getMinutes() < 15) {
                    d2.setMinutes(15)
                }   else if(d2.getMinutes() > 15 && d2.getMinutes() < 30) {
                    d2.setMinutes(30)
                }   else if(d2.getMinutes() > 30 && d2.getMinutes() < 45) {
                    d2.setMinutes(45)
                }   else if(d2.getMinutes() > 45 && d2.getMinutes() < 60) {
                    d2.setMinutes(60)
                }
                timecorrected = true;
                }   else {
                    d2.setMinutes ( d1.getMinutes() + addedMinutes );
                }
                d1 = d2  
                
                let hour = d2.getHours()
                if(hour < 10) {
                    hour = `0${hour}`
                }
                let minutes = d2.getMinutes()
                if(minutes < 10) {
                    minutes = `0${minutes}`
                }
                const hourminsObject = `${hour}:${minutes}`

                options.push({value: hourminsObject, label: hourminsObject})
            }
            setDeliveryTime(options[0].value)
            setOpts(options)

        }
        // eslint-disable-next-line
    }, [todayStartOpenHour, todayEndOpenHour])

    useEffect(() => {
        const today = new Date()
        const currDay = today.getDay()
        setCurrentDay(openHours[currDay])
        handleSetStartHours(openHours[currDay].hours)
        //setTodayStartOpenHour()
        // eslint-disable-next-line
    }, [])


    const options = [{value: 'Jak najszybciej', label: 'Jak najszybciej'}]
    const handleSetDeliveryTime = (e) => {
        setDeliveryTime(e.value)
    }
    const handleSetRefound = (e) => {
        setRefound(e.target.value)
        if(e.target.value > finalPrice) {
            setRefoundAccepted(true)
        }   else {
            setRefoundAccepted(false)
        }
    }

    const handleSetOrder = (e) => {
        e.preventDefault();
        setIsFormSubmitted(true)
        if(minimalPrice < productPrice) {
        const orderObject = {
            name: userData.name,
            surname: userData.surname,
            email: userData.email,
            number: userData.number,
            city: userData.city,
            street: userData.street,
            homeNumber: userData.homeNumber,
            flatNumber: userData.flatNumber,
            deliveryTime,
            refound,
            choosedType,
            paymentType,
            uwagi,
            postCode,
            deliveryPrice,
            finalPrice, 
            authCode: userData.authCode,
            userId: userData.id
        }
        
        props.setNew(orderObject)
        }
    }

    return ( 
        <>
            <form className='orderForm' onSubmit={handleSetOrder}>
                <label>Imię i nazwisko <span>*</span></label>
                <input type="text" readOnly value={`${userData.name} ${userData.surname}`}/>

                <label>Adres E-mail <span>*</span></label>
                <input type="text" readOnly value={userData.email}/>

                <label>Numer telefonu <span>*</span></label>
                <input type="text" readOnly value={userData.number}/>

                <label id='headerGroup'>Sposób dostawy</label>
                <div className="checkDeliveryType">
                  <label><Radio checked={choosedType === 1 ? true : false} onClick={() => setChoosedType(1)}/><p>Dowóz do domu</p></label>
                  <label><Radio checked={choosedType === 2 ? true : false} onClick={() => setChoosedType(2)}/><p>Odbiór osobisty w lokalu</p></label>
                </div>

                { choosedType === 1 ? <>
                <label id='headerGroup'>Adres dostawy</label>
                <div className="savedAdress">
                    <label>
                        <HomeIcon />
                    </label>
                    <label>
                        <p id='savedCity'>{userData.city}</p>
                        <p>ul. {userData.street} {userData.homeNumber}{userData.flatNumber !== null ? `/${userData.flatNumber}` : null }</p>
                    </label>
                </div></> : null }
                <label id='headerGroup'>Czas {choosedType === 1 ? 'dostawy' : 'odbioru'}</label>
                <Select options={opts} id='timeOrder' defaultValue={options[0]} onChange={handleSetDeliveryTime}/>
                <label id='headerGroup'>Sposób płatności</label>
                <div className="paymentsTypes">
                    <label><Radio checked={paymentType === 1 ? true : false} onClick={() => setPaymentType(1)}/><p>Płatność kartą lub blik</p></label>
                    <label><Radio checked={paymentType === 2 ? true : false} onClick={() => setPaymentType(2)}/><p>Płatność gotówką</p></label>
                </div>
                { paymentType === 2 ?<> <label id='headerGroup'>Masz odliczoną kwotę?</label>
                 <div className="refoundCount">
                    <p>Podaj nominał jakim chcesz zapłacić abyśmy mogli być przygotowani żeby wydać resztę.</p>
                    <input type="number" id='refoundInput' value={refound} onChange={handleSetRefound}/>
                    { isFormSubmitted ? refoundAccepted ? <span></span> : <span>Kwota nie może być mniejsza niż wartość zamówienia</span> : <span></span> }
                </div></> : null }

                <label id='headerGroup'>Uwagi do zamówienia</label>
                <textarea id='uwagiOrder' value={uwagi} onChange={handleSetUwagi}></textarea>
                
                <div className="paymentValue">
                    { minimalPrice !== '' ? minimalPrice > productPrice ? <p id='underpayment'>Brakuje ci <span>{handleSetUnderPayment()}zł</span> do minimalnej wartości zamówienia</p> : null : null }
                    <h2>Do zapłaty: {finalPrice}zł</h2>
                    { choosedType === 1 ? <span>W tym cena dostawy {deliveryPrice}zł</span> : null }
                </div>
                <button id='orderBtn'>Zamawiam</button>
            </form>
        </>
     );
}
 
export default LoggedFormData;